body {
  /* background: #22275b; */
  background: #fff;
  /* background: var(--main_gradient-color2); */
  overflow-x: hidden;
}

* {
  font-size: 12px;
}

:root {
  --main_gradient-color: linear-gradient(90deg, #ce0204 0%, #f2403a 100%);
  --main_gradient-color2: linear-gradient(180deg,#ce0204,#f2403a);
  --main_gradient-color3: linear-gradient(180deg,#f2403a,#ce0204);
  --main_gradient-for-button-bg-color: linear-gradient(180deg, #2AAAF3 0%, #2979F2 100%);
  --main_gradient-color2-silver: linear-gradient(180deg, #adadad 0%, #7d7e80 100%);
  --main-green-bg-gradient: linear-gradient(180deg, #3ae258 0%, #00ff2e 100%);
  --main-color: #f2413b;

  --norm_secondary-color-for-vip-level-benefits: #fff;

  --norm_red-color: #fb5b5b;
  --norm_green-color: #18b660;
  --norm_secondary-color: #feaa57;
  --norm_Purple-color: #c86eff;
  --norm_bule-color: #6ea8f4;
}

.gradient-bg-btn-silver {
  background: var(--main_gradient-color2-silver);
  padding: 5px;
  text-align: center;
  border: none;
  color: #fff;
  width: 100%;
  font-size: 16px;
  border-radius: 20px;
}

.gradient-bg-btn {
  background: var(--main_gradient-color2);
  padding: 5px;
  text-align: center;
  border: none;
  color: #fff;
  width: 100%;
  font-size: 16px;
  border-radius: 20px;
}
.allgameWhiteBox {
  background-color: #fff;
}

/* BottomNav CSS */
.bottomNav {
  position: fixed;
  bottom: 0;
  /* box-shadow: 0px 0px 2px 0px; */
  z-index: 1000;
}

.bottomNav img {
  width: 100%;
  position: relative;
}

.botInfo {
  position: absolute;
  top: 0;
  text-align: center;
  padding-top: 3px;
  color: #acafc2;
}

.changeColor {
  color: gray;
  text-align: center;
  padding-top: 3px;
}

.botInfo h4,
.changeColor h4 {
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 1.25rem;
}

.botImg img {
  width: 60px;
  margin-top: -25px;
}

.botImg i {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
}

/*  */
/* Header */

.slotHeadText {
  display: flex;
  padding: 9px;
  background: #0A1846;
  color: white;
  height: 52px;
}

.slotHeadText i {
  font-size: 20px;
  padding-top: 4px;
  padding-left: 5px;
}

.slotHeadText h3 {
  padding-top: 4px;
  margin-left: 28px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
}

.minibg {
  background: var(--main_gradient-color2);
}

/* Header 2 */
.miniHeadForBg {
  display: flex;
  padding: 10px;
  background: var(--main_gradient-color2);
  color: white;
  height: 52px;
}

.miniHeadForBg i:first-of-type {
  font-size: 20px;
  padding-top: 4px;
  position: absolute;
  left: 13px;
}

.miniHeadForBg i {
  font-size: 24px;
  padding-top: 2.5px;
  position: absolute;
  right: 15px;
}

.miniHeadForBg h3 {
  padding-top: 4px;
  margin-left: 40px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
}

/* Header 2 */
.miniHead {
  display: flex;
  padding: 10px;
  background: #0A1846;
  color: white;
  height: 52px;
}

.miniHead i:first-of-type {
  font-size: 20px;
  padding-top: 4px;
  position: absolute;
  left: 13px;
}

.miniHead i {
  font-size: 24px;
  padding-top: 2.5px;
  position: absolute;
  right: 15px;
}

.miniHead h3 {
  padding-top: 4px;
  margin-left: 40px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
}

/* Header 2 */
.miniHeadforWallet {
  display: flex;
  padding: 10px;
  color: white;
  height: 52px;
}

.miniHeadforWallet i:first-of-type {
  font-size: 20px;
  padding-top: 4px;
  position: absolute;
  left: 13px;
}

.miniHeadforWallet i {
  font-size: 24px;
  padding-top: 2.5px;
  position: absolute;
  right: 15px;
}

.miniHeadforWallet h3 {
  padding-top: 4px;
  margin-left: 40px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
}

/*  */
/* Header 3 */

.gameTop {
  display: flex;
  padding: 10px;
  background: #0A1846;
  color: white;
  height: 52px;
}

.gameTop i:first-of-type {
  font-size: 20px;
  padding-top: 4px;
  position: absolute;
  left: 13px;
}

.gameTop i {
  font-size: 24px;
  padding-top: 2.5px;
  position: absolute;
  right: 15px;
}

.gameTop h3 {
  padding-top: 4px;
  margin-left: 40px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
}

.gameTop h4 {
  position: absolute;
  right: 55px;
  padding-top: 4px;
  margin-left: 40px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
}

/*  */
/* Header 3 */

.gameTopColorPrediction {
  display: flex;
  padding: 10px;
  background: var(--main_gradient-color3);
  color: white;
  height: 52px;
}

.gameTopColorPrediction i:first-of-type {
  font-size: 20px;
  padding-top: 4px;
  position: absolute;
  left: 13px;
}

.gameTopColorPrediction i {
  font-size: 24px;
  padding-top: 2.5px;
  position: absolute;
  right: 15px;
}

.gameTopColorPrediction h3 {
  padding-top: 4px;
  margin-left: 40px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
}

.gameTopColorPrediction h4 {
  position: absolute;
  right: 55px;
  padding-top: 4px;
  margin-left: 40px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
}

/*  */
/* Header 4 */

.diceHead {
  display: grid;
  grid-template-columns: 10fr 60fr 10fr 5fr 10fr;
  column-gap: 15px;
  padding: 15px 10px;
  background: #0A1846;
  color: white;
  height: 52px;
}

.diceHead i {
  font-size: 20px;
}

.diceHead h3 {
  margin-top: 0;
  margin-bottom: 0;
  text-wrap: nowrap;
  font-weight: 500;
  font-size: 18px;
}

.diceHead h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
}

/*  */
a:-webkit-any-link {
  color: rgb(82, 81, 81) !important;
  text-decoration: none !important;
}

.loader {
  text-align: center;
  margin-top: 250px;
}

/*  */
/* SignIn CSS */

.signinHead {
  padding-top: 2px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  background: #0A1846;
}

.signinHead h4 {
  margin-top: 6px;
  margin-bottom: 0;
  color: white;
  font-size: 12px;
}

.signinBox {
  margin-top: 20px;
}

.signinInput {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
  color: #f0f1f5;
}

.signinInput input {
  font-weight: bold;
  font-size: 16px;
  padding: 15px 12px;
  margin-bottom: 24px;
  border: none;
  border-radius: 10px;
  color: var(--main-color);
  /* background: #2b3270; */
  background: #E8F0FE;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.signinInput input::placeholder {
  /* color: gainsboro; */
  color: #aaa;
  opacity: 1;
}

.signinInput i {
  position: absolute;
  top: 20px;
  right: 6px;
  font-size: 16px;
}

.signinBtn {
  display: grid;
  margin-top: 20px;
}

.signinBtn button {
  justify-self: center;
  width: 90%;
  padding: 12px;
  /* background: blue; */
  background: var(--main_gradient-color2);
  color: white;
  border: none;
  font-weight: 500;
  font-size: 15px;
  border-radius: 10px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.signinInfo {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.signinInfo h4 {
  padding: 10px 15px;
  margin-left: 6px;
  margin-right: 6px;
  color: white;
  /* background: blue; */
  background: var(--main_gradient-color2);
  font-size: 14px;
  font-weight: 500;
  border-radius: 25px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

/*  */
/* SignUp CSS */

.otpInput {
  display: grid;
  grid-template-columns: 80fr 20fr;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
}

.otpInput input {
  margin-right: 3px;
  margin-left: 2px;
  font-weight: bold;
  font-size: 16px;
  padding: 15px 12px;
  margin-bottom: 24px;
  border: none;
  border-radius: 10px;
  color: white;
  /* background-color: #2b3270; */
  background-color: var(--main_gradient-color2);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.otpInput input::placeholder {
  color: gainsboro;
  opacity: 1;
}

.otpInput h4 {
  text-align: center;
  height: 52px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 17px;
  padding-bottom: 8px;
  padding-left: 22px;
  padding-right: 22px;
  margin-left: 6px;
  margin-right: 6px;
  color: white;
  background: blue;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.privacyText {
  display: flex;
  margin-left: 20px;
}

.privacyText h4 {
  margin-top: 0;
  margin-bottom: 0;
  color: gray;
  font-size: 14px;
  font-weight: 600;
  padding-left: 2px;
}

.privacyText h4 i {
  padding-right: 7px;
  font-size: 14px;
}

.privacyText h4 span {
  color: #0081ff;
  padding-left: 4px;
}

.signupBtns {
  display: grid;
  grid-template-columns: 50fr 50fr;
  justify-content: center;
  column-gap: 15px;
  margin-left: 15%;
  margin-right: 15%;
}

.signupBtn {
  display: grid;
  margin-top: 20px;
}

.signupBtn button {
  padding: 12px;
  /* background: blue; */
  background: var(--main_gradient-color2);
  width: 100%;
  color: white;
  border: none;
  border-radius: 25px;
  font-weight: 500;
  font-size: 15px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

/*  */
/* Search CSS */

.searchCont {
  margin-top: 18px;
}

.allProductsBox {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding-top: 15px;
  margin-bottom: 50px;
  margin-top: -7px;
  background: white;
}

.allProducts {
  display: grid;
  grid-template-columns: 50fr 50fr;
  margin-left: 20px;
  margin-right: 20px;
  column-gap: 16px;
}

.productCard {
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 15px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.productCard img {
  width: 100%;
  height: 210px;
}

.productCard h5:first-of-type {
  color: black;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
}

.productCard h5 {
  margin-top: 10px;
  margin-bottom: 0;
  color: #f39839;
}

/*  */
/* Home CSS */

.homeCont {
  padding-bottom: 15%;
}

.homeNav {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 10px;
  background: var(--main_gradient-color2);
  color: #f0f1f5;
  height: 55px;
}

.homeNav img {
  margin-top: -5px;
}

.homeNav h3 {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
}

.homeNav a {
  width: 90px;
  height: 30px;
  margin: 3px 0;
  padding: 6px;
  /* font-size: 12px; */
  border: none;
  border-radius: 3px;
  font-weight: 600;
  color: brown;
  background: yellow;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.homeNav i {
  padding-top: 7px;
  padding-right: 5px;
  font-size: 20px;
}

.homeTxt {
  margin-top: 50%;
  text-align: center;
}

.homeTxt h5 {
  font-size: 20px;
}

.allhomeFlex {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 15px;
  margin: 5px 10px;
}

.homeFlex {
  display: flex;
  column-gap: 10px;
  padding: 5px;
  color: white;
  background: rgb(89, 89, 255);
  border-radius: 10px;
}

.homeFlex img {
  width: 70px;
}

.homeFlex h4 {
  margin-top: 15px;
  font-size: 14px;
}

.allthreeFlex {
  display: grid;
  grid-template-columns: 33fr 33fr 33fr;
  column-gap: 10px;
  margin: 10px;
}

.threeFlex {
  display: flex;
  padding: 5px;
  color: white;
  background: rgb(231, 52, 231);
  border-radius: 10px;
  position: relative;
}

.threeFlex img {
  width: 85px;
}

.threeFlex h4 {
  margin-top: 5px;
  font-size: 10px;
  position: absolute;
  right: 5px;
}

.allhomeWin {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 10px;
  margin-top: 15px;
}

.homeWin {
  display: flex;
  padding: 5px;
  color: white;
  background: var(--main_gradient-color2);
  border-radius: 10px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.homeWin img {
  width: 55px;
  height: 55px;
  border-radius: 40px;
  border: 1px solid rgb(230, 230, 230);
}

.homeWin h4 {
  margin-top: 12px;
  margin-left: 7px;
}

/*  */
/* Profile CSS */

.profileBox {
  position: relative;
  height: 248px;
  background: white;
}

.profileHead {
  display: grid;
  grid-template-columns: 10fr 80fr 10fr;
  column-gap: 10px;
  color: white;
  /* background: #22275b; */
  height: 15rem;
  background: var(--main_gradient-color2);
  border-bottom: 2px solid white;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 30px 16px;
}

.ColorPredictionHead {
  color: white;
  background: var(--main_gradient-color2);
  border-bottom: 2px solid white;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  /* padding: 30px 16px; */
  padding-top: 20px;
  padding-bottom: 50px;
}

.profileImg img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.profileText {
  margin-top: 1.5px;
  font-size: 15px;
}

.profileText h3 {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
}

.notifyIcon {
  text-align: end;
  padding-right: 20px;
}

.notifyIcon i {
  margin-top: 7px;
  background: white;
  /* color: gray; */
  color: var(--main-color);
  border-radius: 22px;
  font-size: 22px;
  padding: 10px;
}

.notifyBtn {
  display: grid;
  justify-content: flex-end;
  margin-top: 25px;
}

.notifyBtn button {
  background: white;
  color: #0081ff;
  border: none;
  font-size: 14px;
}

.balBox h3 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
  font-weight: 700;
  font-size: 16px;
}

.balBox h4 {
  margin-top: 5px;
  margin-bottom: 0;
  margin-left: 5px;
  font-weight: 500;
  font-size: 14px;
  text-wrap: nowrap;
}

.profileCont {
  margin-bottom: 70px;
}

.profileList {
  background: white;
}

.listBox {
  display: grid;
  grid-template-columns: 5fr 85fr 10fr;
  padding-left: 17px;
  padding-top: 14px;
  padding-bottom: 14px;
  color: black;
  background: white;
  border-top: 1px solid gainsboro;
  border-bottom: 1px solid gainsboro;
}

.listBox:hover {
  background: var(--main_gradient-color2);
  color: white;
}

.listBox h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 15px;
  padding-left: 15px;
}

.listBox i {
  padding-top: 1px;
  font-size: 14px;
}

.miniListBox {
  padding-left: 55px;
  color: gray;
  margin-bottom: 10px;
}

.miniListBox:hover {
  background: var(--main_gradient-color2);
  color: white;
}

.miniListBox h4 {
  margin-top: 0;
  margin-bottom: 0;
  color: black;
  font-weight: 500;
  font-size: 15px;
  font-family: futura;
  padding-top: 5px;
  padding-bottom: 3px;
}

.miniListBox a h4:hover {
  color: white;
  background: var(--main_gradient-color2);
}

.logoutBtn {
  margin-left: 10px;
  margin-right: 11px;
}

.logoutBtn button {
  color: #ffffff;
  width: 100%;
  padding: 8px;
  background: var(--main_gradient-color2);
  border-radius: 20px;
  font-size: 16px;
  border: none;
  margin-top: 10px;
  margin-bottom: 50px;
}

.whiteBox {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  background: white;
  border-radius: 15px;
  border: 1px solid gainsboro;
  width: 92%;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: auto;
  margin-right: auto;
}

.serviceTxt h4 {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 10px;
  color: black;
}

.allserviceIcons {
  display: grid;
  grid-template-columns: 33.33fr 33.33fr 33.33fr;
  gap: 10px;
  margin: 15px 7px;
}

.serviceIcon {
  text-align: center;
}

.serviceIcon p {
  margin-top: 4px;
  color: #000;
  font-size: 13px;
}

.serviceIcon .css-i4bv87-MuiSvgIcon-root {
  /* color: white !important; */
  color: #000 !important;
  font-size: 2.8rem !important;
}

.css-vubbuv {
  font-size: 3rem !important;
  color: #454e9e !important;
  fill: #454e9e !important;
}

/*  */
/* Win CSS */

.winCont {
  margin-bottom: 15%;
}

.winBox {
  background: #0081ff;
  padding-top: 25px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 10px;
  margin-left: 13px;
  margin-right: 13px;
  margin-top: 15px;
}

.winBal h3 {
  margin-top: 0;
  margin-bottom: 0;
  color: white;
  font-weight: 500;
  font-size: 17px;
}

.winBtn {
  display: flex;
  position: relative;
  margin-top: 15px;
}

.winBtn button {
  background: #58e95d;
  color: white;
  border: none;
  padding: 8px 18px;
  font-weight: 500;
  font-size: 14px;
  margin-right: 12px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.winBtn h4 {
  margin-top: 11px;
  margin-bottom: 0;
  font-size: 11px;
  padding: 3px 5px;
  height: fit-content;
  color: red;
  background: white;
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.winBtn i {
  position: absolute;
  right: 0;
  bottom: 6px;
  color: white;
  font-size: 20px;
}

.winParity h5 {
  color: #fff;
  margin-top: 0px;
  /* margin-bottom: 0px; */
  text-align: center;
  font-size: 20px;
}

.winMinicont {
  margin-left: 10px;
  margin-right: 10px;
  /* border: 2px solid black; */
}

.winTimebox {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 0.2px;
  text-align: center;
  padding: 10px;
  /* display: flex;
  justify-content: space-between;
  border-top: 3px solid #0081ff;
  padding: 10px 13px; */
}

/* .winPeriod {
  color: white;
  margin-top: 5px;
}

.winPeriod h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.winPeriod h5 i {
  padding-right: 5px;
  padding-top: 2px;
}

.winPeriod h3 {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 500;
}

.winCount {
  padding: 4px 10px;
  text-align: center;
  border-radius: 8px;
  border: 3px solid red;
  border-style: dashed;
}

.winCount h5 {
  margin-top: 0;
  margin-bottom: 0;
  color: rgb(240, 240, 253);
  font-size: 16px;
  font-weight: 700;
}

.winCount h3 {
  color: greenyellow;
  margin-top: 2px;
  margin-bottom: 0;
  font-size: 29px;
  font-weight: 500;
  padding-left: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
} */

.winTabs {
  display: grid;
  grid-template-columns: 25fr 25fr 25fr 25fr;
  column-gap: 10px;
  /* background: #454e9e; */
  background: #F6F6F6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 5px 10px;
  margin-top: -60px;
  padding: 5px;
}

.winWatch {
  padding: 5px;
  color: gray;
  text-align: center;
}

.clickWatch {
  padding: 5px;
  color: white;
  /* background: #0A1846; */
  background: var(--main_gradient-color2);
  text-align: center;
  border-radius: 5px;
}

.winWatch img,
.clickWatch img {
  width: 45px;
}

.winWatch h4,
.clickWatch h4 {
  margin-top: 2px;
  font-size: 14px;
}

.winTxtBox {
  background: var(--main_gradient-color2);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.2);
}

.winTxt h5 {
  margin-top: 0;
  margin-bottom: 0;
  color: white;
  font-size: 15px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid white;
}

.winTxt h6 {
  margin-top: 10px;
  margin-bottom: 0;
  color: white;
  font-size: 13px;
}

.winPlay {
  display: flex;
  justify-content: center;
  column-gap: 6px;
  margin-top: 10px;
}

.winPlay img {
  width: 30px;
}

.winTimebox {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 0.2px;
  gap: 10px;
  text-align: center;
  padding: 10px;
}

.winCount {
  /* background: #454e9e; */
  background: var(--main_gradient-color2);
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.2);
}

.winCount h5 {
  margin-top: 0;
  margin-bottom: 0;
  color: white;
  font-size: 14px;
}

.winCount h3 {
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 29px;
  font-weight: 500;
  padding-left: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  color: white;
}

.winCount h3 span {
  margin-top: 0;
  margin-bottom: 0;
  color: #2b3270;
  padding: 2px 5px;
  background: white;
  border-top-left-radius: 7px;
  border-bottom-right-radius: 7px;
  font-size: 26px;
}

.winCount h6 {
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  color: white;
  font-size: 17px;
  font-weight: 500;
}

.joinBtn {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 6px;
  margin-left: 10px;
  margin-right: 10px;
  column-gap: 15px;
}

.joinBtn1 {
  width: 100%;
  color: white;
  background: #17b15e;
  border: none;
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 500;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.joinBtn2 {
  width: 100%;
  color: white;
  background: #9c27b0;
  border: none;
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.joinBtn3 {
  width: 100%;
  color: white;
  background: #d23838;
  border: none;
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 500;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.sorrycard {
  position: relative;
}

.sorrycard img {
  width: 100%;
  display: block;
}

.sorrycardTxt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.sorrycardTxt h3 {
  margin-top: 150px;
  font-size: 28px;
  font-weight: 700;
}

.sorrycardTxt h4 {
  margin-top: 72px;
  color: red;
  font-size: 15px;
  font-weight: 700;
}

.sorrycardTxt h5 {
  margin-top: 5px;
  font-size: 30px;
  font-weight: 700;
}

.sorrycardTxt h6 {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
  color: gray;
}

.blueColor {
  color: blue;
}

.yellowColor {
  color: yellow;
}

.newredColor {
  color: red;
}

.luckyImg img {
  width: 70px;
  height: fit-content;
}

.luckyImg2 img {
  width: 70px;
  height: fit-content;
}

.luckyImg3 img {
  width: 70px;
  height: fit-content;
}

.greyBtn {
  width: 100%;
  color: white;
  background: rgb(189, 187, 187);
  border: none;
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.greenBot {
  padding: 18px;
  text-align: center;
  background: #17b15e;
}

.greenBot h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
  color: white;
}

.greenBot h5 {
  padding: 3px;
  margin-top: 8px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  font-weight: 700;
  width: 80%;
  background: white;
  border-radius: 5px;
}

.greenBox {
  /* background: #0A1846; */
  background: #ffffff;
}

.greenBal {
  display: flex;
  justify-content: space-between;
  color: var(--main-color);
  background: #ffffff;
  padding-top: 15px;
}

.greenBal h4 {
  padding: 7px;
}

.greenMoney {
  display: flex;
  margin: 5px;
  /* box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); */
}

.greenMoney button {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 8px;
  font-weight: 500;
  font-size: 15px;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  color: black;
  /* background: #454e9e; */
  background: #eee;
  /* background: var(--main_gradient-color2); */
}

.clickedMoney button {
  background: #17b15e;
}

.greenQuantity {
  display: grid;
  grid-template-columns: 25fr 50fr 25fr;
  margin: 0 5px;
}

.greenQuantity button {
  background-color: #17b15e;
  color: white;
  padding: 6px 10px;
  height: fit-content;
  border: none;
  border-radius: 3px;
}

.greenQuantity button i {
  font-size: 12px;
}

.greenQuantity h3 {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.greenBtns {
  display: grid;
  grid-template-columns: 30fr 70fr;
}

.greenBtns button {
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  color: white;
  /* background: #454e9e; */
  background: var(--main_gradient-color2);
}

.greenSale {
  display: flex;
  padding-bottom: 25px;
  padding-left: 7px;
  margin-top: 15px;
}

.greenSale h4 {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--main-color);
  font-size: 14px;
  font-weight: 600;
  padding-left: 2px;
}

.greenSale h4 i {
  padding-right: 7px;
  font-size: 14px;
}

.greenSale span {
  color: #0081ff;
  padding-left: 4px;
}

.winNumbers {
  display: grid;
  grid-template-columns: 20fr 20fr 20fr 20fr 20fr;
  /* text-align: center;
  margin-top: 3px;
  margin-left: 2px;
  margin-right: 2px; */
}

/* .winNumbers h4 {
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: 6px;
  margin-right: 6px;
  padding: 5px;
  color: white;
  border-radius: 3px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
} */

.winNumbers img {
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  width: 55px;
  height: 55px;
}

.winImg {
  display: grid;
  grid-template-columns: 25fr 25fr 25fr 25fr;
  column-gap: 28px;
  margin: 10px;
}

.winImg img {
  margin-top: 15px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.2);
}

.winRecord {
  display: grid;
  justify-content: center;
  text-align: center;
  padding-top: 23px;
  padding-bottom: 15px;
  margin-bottom: 1px;
  color: var(--main-color);
  /* background: #2b3270; */
  background: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.winRecord i {
  font-size: 20px;
}

.winRecord h5 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
}

.resCont {
  background: white;
  box-shadow: 0 3px -3px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.tableColor {
  display: flex;
  justify-content: center;
}

.tableColor h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.colorRed {
  width: 15px;
  height: 15px;
  background: red;
  border-radius: 12px;
}

.colorGreen {
  width: 15px;
  height: 15px;
  /* background: greenyellow; */
  background: green;
  border-radius: 12px;
}

.colorViolet {
  width: 15px;
  height: 15px;
  background: violet;
  border-radius: 12px;
  margin-left: 2px;
}

.betCont {
  background: white;
}

.betHead {
  margin-left: 20px;
  margin-bottom: 20px;
}

.betHead h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
}

.betRecord {
  display: grid;
  grid-template-columns: 60fr 40fr;
  margin-left: 20px;
  margin-top: 15px;
}

.betRecord h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 500;
}

.betRecord h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 600;
}

.greyNums {
  display: grid;
  grid-template-columns: 20fr 20fr 20fr 20fr 20fr;
  text-align: center;
  margin-top: 3px;
  margin-left: 2px;
  margin-right: 2px;
}

.greyNums h4 {
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: 6px;
  margin-right: 6px;
  padding: 5px;
  color: white;
  background: rgb(189, 187, 187);
  border-radius: 3px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.pageBtn {
  background: var(--main_gradient-color2);
  text-align: right;
}

.pageBtn button {
  background: var(--main_gradient-color2);
  color: white;
  border: none;
}

.pageBtn button i {
  font-size: 16px;
  padding: 8px 10px;
}

/*  */
/* GreenHead Modal */

.greenHead h2 {
  background: rgb(76, 175, 80);
  color: white;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  font-weight: 400;
}

.greenHeadBox {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
  background: white;
  /* border: 2px solid black; */
}

.greenHeadTxt h5 {
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: 500;
}

.greenHeadBtn {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  width: fit-content;
  margin-top: 13px;
  display: flex;
}

.greenHeadBtn button {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  border: none;
  padding: 10px 10px;
  background: white;
  color: gray;
}

.violetColor button {
  background: #9b48db;
}

.redColor button {
  background: #d23838;
}

.numColor button {
  font-weight: 700;
  /* background: white; */
  /* color: #0A1846; */
  background: var(--main_gradient-color2);
  color: white;
}

.bigColor button {
  background: #dd9138;
}

.smallMoney button {
  background: #5088d3;
}

.greenHeadCount {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.greenHeadCount button {
  width: 55px;
  height: 35px;
  background-color: #f5f5f5;
  color: rgb(54, 54, 54);
  border-radius: 3px;
  border: 1px solid rgb(209, 209, 209);
}

.greenHeadCount button i {
  font-size: 17px;
}

.greenHeadCount button:hover {
  background: #0081ff;
  color: white;
}

.greenHeadCount h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 500;
}

.greenHeadTxt2 {
  display: flex;
  margin-top: 25px;
}

.greenHeadTxt2 h4 {
  margin-top: 0;
  margin-bottom: 0;
  color: gray;
  font-size: 14px;
  font-weight: 600;
  padding-left: 2px;
}

.greenHeadTxt2 h4 i {
  padding-right: 7px;
  font-size: 14px;
}

.greenHeadTxt2 span {
  color: #0081ff;
  padding-left: 4px;
}

.violetHead h2 {
  background: #9c27b0;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  font-weight: 400;
}

.redHead h2 {
  background: #f44336;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  font-weight: 400;
}

.numHead h2 {
  background: rgb(33, 150, 243);
  color: white;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  font-weight: 400;
}

/*  */
/* Modal */

.signinModalTxt h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 500;
}

.signinModal {
  display: flex;
  justify-content: flex-start;
  column-gap: 20px;
}

.signinModal h5 {
  margin-top: 18px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px !important;
}

.signinModalBtn {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
  margin-top: 30px;
}

.signinModalBtn button:first-of-type {
  background: white;
  color: gray;
  border: none;
  font-size: 14px;
}

.signinModalBtn button {
  background: white;
  color: #0081ff;
  border: none;
  font-size: 14px;
}

/* Modal 2 */
.promotionTab {
  height: 380px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.promotionTabBtn {
  position: absolute;
  bottom: 0;
  right: 24px;
  padding-bottom: 14px;
}

.promotionTabBtn button {
  background: white;
  color: #0081ff;
  border: none;
}

/*  */
/* LuckyHit CSS */

.luckyTime {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 0.2px;
  gap: 10px;
  text-align: center;
  padding: 10px;
}

.luckyTxt {
  padding: 10px;
  border-radius: 10px;
  background: var(--main_gradient-color2);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.luckyTxt h5 {
  margin-top: 0;
  margin-bottom: 0;
  color: white;
  font-size: 15px;
  padding: 5px;
  border-radius: 5px;
}

.luckyTxt h6 {
  margin-top: 10px;
  margin-bottom: 0;
  color: white;
  font-size: 18px;
}

.lcukyCount {
  padding: 15px;
  /* background: #2b3270; */
  background: var(--main_gradient-color2);;
  border-radius: 10px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.lcukyCount h5 {
  margin-top: 0;
  margin-bottom: 0;
  color: white;
  font-size: 14px;
}

.lcukyCount h3 {
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 29px;
  font-weight: 500;
  padding-left: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  color: white;
}

.lcukyCount h3 span {
  margin-top: 0;
  margin-bottom: 0;
  /* color: blue; */
  color: var(--main-color);
  padding: 2px 5px;
  background: white;
  border-top-left-radius: 7px;
  border-bottom-right-radius: 7px;
  font-size: 26px;
}

.luckyTabs .css-heg063-MuiTabs-flexContainer {
  /* background: #2b3270 !important; */
  background: var(--main_gradient-color2) !important;
  margin-top: 8px !important;
  display: grid !important;
  grid-template-columns: 50fr 50fr !important;
}

.luckyTabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  /* background: #2b3270 !important; */
  background: var(--main_gradient-color2) !important;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.2); */
}

.scroll-container {
  margin: 5px 10px;
  height: 220px;
  overflow: hidden;
  border-radius: 10px;
  background: var(--main_gradient-color2);
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.2);
}

.scrollingImg {
  position: relative;
  display: grid;
  grid-template-columns: 33fr 33fr 33fr;
  animation: scrollDown 1s linear infinite;
}

.scrollingImg img {
  justify-self: center;
  height: 135px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.2);
}

@keyframes scrollDown {
  0% {
    top: -100%;
  }

  100% {
    top: 100%;
  }
}

.singleImg {
  padding-top: 8px;
  margin: 5px 10px;
  text-align: center;
  border-radius: 10px;
  color: white;
  /* background: #2b3270; */
  background: var(--main_gradient-color2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.2);
}

.singleImg img {
  width: 110px;
  border: 3px solid gold;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.2);
}

/*  */
.css-1cuxlhl {
  display: none;
}

.css-12w9als {
  padding-left: 32px;
  font-weight: 500;
  color: gray;
}

/* AddBankCard CSS */
.bankInput {
  display: grid;
  padding: 5px 26px;
  margin-top: 18px;
}

.bankInput label {
  color: var(--main-color);
  font-size: 12.5px;
  font-weight: 500;
}

.bankInput input {
  border: none;
  border-bottom: 1px solid var(--main-color);
  margin-top: 7px;
  font-size: 16px;
  background: none;
  color: var();
}

.otpInputCard {
  display: grid;
  grid-template-columns: 80fr 20fr;
  justify-content: center;
  margin-top: 10px;
}

.otpInputCard input {
  margin-right: 3px;
  font-weight: bold;
  font-size: 15px;
  padding: 15px 12px;
  margin-bottom: 24px;
  margin-top: 4px;
  border: none;
  border-bottom: 1px solid #919191;
  background: #fafafa;
}

.otpInputCard h4 {
  text-align: center;
  height: 52px;
  margin-top: 4px;
  margin-bottom: 0;
  padding-top: 17px;
  padding-bottom: 8px;
  padding-left: 22px;
  padding-right: 22px;
  margin-left: 6px;
  background: #f5f5f5;
  font-size: 13px;
  font-weight: 500;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.signinBtnCard {
  display: grid;
  margin-bottom: 20px;
  margin-top: 10px;
}

.signinBtnCard button {
  justify-self: center;
  width: 100%;
  padding: 12px;
  background: #0081ff;
  /* background: var(--main_gradient-color2); */
  color: white;
  border: none;
  font-weight: 500;
  font-size: 15px;
  border-radius: 20px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

/*  */
/* Add Address CSS */
.addInputCont {
  margin-bottom: 22px;
}

/*  */
/* Recharge CSS */

.balCont {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: white;
  background: var(--main_gradient-color2);
  padding-bottom: 40px;
  padding-top: 3px;
}

.WalletbalCont {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #000;
  /* background: #2b3270; */
  /* background: var(--main_gradient-color2); */
  padding-bottom: 40px;
  padding-top: 3px;
}

.WalletbalContTwo {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #000;
  padding-bottom: 40px;
}

.balInfo {
  margin-top: 30px;
}

.balText {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 25px;
}

.balText h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 450;
}

.balText input {
  margin-top: 16px;
  width: 90%;
  height: 48px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 0 15px;
  border: none;
  font-size: 14px;
}

.balBtns {
  display: grid;
  grid-template-columns: 33fr 33fr 33fr;
  margin: 25px 35px;
}

.balBtns button {
  margin: 5px 5px;
  padding: 10px 0;
  border: none;
  font-size: 14px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.balBtns button:hover {
  background: #2196f3;
  color: white;
}

.balIcons {
  display: grid;
  grid-template-columns: 25fr 25fr 25fr 25fr;
  margin-top: 30px;
  margin-left: 7px;
  margin-right: 7px;
}

.balIconInfo {
  text-align: center;
}

.balIconInfo p {
  font-size: 14px;
}

.balIconInfo .css-i4bv87-MuiSvgIcon-root {
  font-size: 2.8rem !important;
}

.circleFlex {
  display: flex;
  justify-content: space-around;
  column-gap: 5px;
  margin: 15px;
}

.css-ol7wxs-MuiTypography-root {
  font-size: 18px !important;
  color: white !important;
}

.css-rqnof7 {
  color: white !important;
  font-weight: 700 !important;
  font-size: 1.55rem !important;
}

/* .balText2 h5 {
  margin-top: 25px;
  margin-bottom: 0;
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  margin-left: 30px;
}

.payText {
  display: flex;
  margin-left: 18px;
  margin-top: 30px;
  margin-bottom: 35px;
  color: #757575;
}

.payText h5 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 30px;
} */

/*  */
/* React Tabs */
.css-heg063-MuiTabs-flexContainer {
  background: #0081ff !important;
  margin-top: 1px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  background: #0081ff !important;
  color: white !important;
  font-size: 13px !important;
  min-width: fit-content !important;
  padding: 15px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: white !important;
}

.css-1aquho2-MuiTabs-indicator {
  background: white !important;
  height: 4px !important;
}

.css-19kzrtu {
  padding: 0 !important;
}

/*  */
/* Reward CSS */

.rewardText {
  text-align: center;
  background: white;
  padding: 30px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.rewardText h5 {
  font-weight: 500;
  color: gray;
}

/*  */
/* Accordian CSS */

.avAccordian .css-1aj41gs {
  background: #373636 !important;
}

.proBar .css-1aj41gs {
  background: #22275b !important;
}

.css-1aj41gs {
  background: white !important;
}

.css-1gqug66 {
  color: white !important;
  background-color: #22275b !important;
}

.css-122laul-MuiTypography-root {
  font-size: 17px !important;
  width: 35px !important;
}

.slideHistory .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  color: white !important;
  background: #373636;
  border-radius: 7px !important;
  position: static !important;
}

.css-10h3w9b-MuiTypography-root {
  color: black !important;
  font-size: 16px !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 2px !important;
}

.css-1v8a2rx-MuiTypography-root {
  font-weight: 500 !important;
  font-size: 15px !important;
  font-family: futura !important;
  color: black !important;
}

.css-o4b71y-MuiAccordionSummary-content {
  font-weight: 700 !important;
  margin: 0 !important;
}

.css-1iji0d4 {
  width: 97% !important;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root:last-of-type {
  /* background: #2b3270 !important; */
  background: var(--main_gradient-color2) !important;
}

.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
  background: white !important;
}

.css-7y0u3q-MuiAccordionDetails-root {
  padding: 8px !important;
}

/*  */
/* Complaints CSS */

.complaintsTabs .css-heg063-MuiTabs-flexContainer {
  display: grid !important;
  grid-template-columns: 50fr 50fr !important;
}

.complaintsTabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  border-top: 1px solid white !important;
  background: #0A1846 !important;
}

/*  */
/* Add Complaints CSS */

.compCont {
  margin-bottom: 22px;
  background: white;
  padding-top: 10px;
  padding-bottom: 18px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.compText {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
}

.compText h5 {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}

/*  */
/* Table Mui */

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  background: white !important;
  overflow-x: hidden !important;
  padding-left: 8px !important;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  background: white !important;
  overflow-x: hidden !important;
  width: 100% !important;
  box-shadow: none !important;
}

.css-1ex1afd-MuiTableCell-root {
  padding: 12px 8px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  text-align: center !important;
  border: none !important;
  color: #000 !important;
  /* background: #22275b !important; */
  background: #fff !important;
}

.css-1azl6jz-MuiTableCell-root {
  font-size: 12px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: white !important;
  background: var(--main_gradient-color2) !important;
}

.css-177gid-MuiTableCell-root {
  padding: 5px 2px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  text-align: center !important;
  border: none !important;
  color: #000 !important;
  background: #fff !important;
}

.css-1ygcj2i-MuiTableCell-root {
  padding: 0px 5px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: white !important;
  /* background: #2b3270 !important; */
  background: var(--main_gradient-color2) !important;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
  margin-left: 0 !important;
}

.css-1ps6pg7-MuiPaper-root {
  box-shadow: none !important;
}

.css-y9hu8e-MuiButtonBase-root-MuiAccordionSummary-root {
  position: static !important;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  transform: none !important;
}

/*  */
/* Withdrawal CSS */

.withCont {
  color: var(--main-color);
  /* background: #22275b; */
  background: #fff;
  padding-top: 5px;
  padding-bottom: 10px;
  /* box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); */
}

.withCard {
  margin-left: 33px;
  margin-right: 33px;
}

.withText h5 {
  margin-top: 25px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
}

.withText2 h5 {
  font-size: 14px;
  color: #757575;
  margin-top: 20px;
}

.withData {
  display: flex;
  margin-top: 30px;
  margin-bottom: 12px;
  color: #757575;
}

.withData h5 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 30px;
}

.withInfo .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  background: white !important;
  padding: 0 !important;
  margin-top: 20px !important;
}

.withListBox {
  color: var(--main-color);
  background: white;
  border: 2px solid var(--main-color);
  text-align: center;
}

.withListBox h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 550;
  font-size: 13px;
  padding: 5px;
}

.clickedUpi h4 {
  padding: 5px;
  font-weight: 550;
  font-size: 13px;
  background: rgb(214, 214, 214);
  text-align: center;
  border-radius: 3px;
}

.withInput {
  margin-top: 25px;
  margin-bottom: 35px;
  position: relative;
}

.withInput input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #919191;
  font-size: 14px;
  background: none;
  padding-bottom: 6px;
}

.withInput i {
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 16px;
}

.css-19sg2bh-MuiTypography-root {
  font-size: 16px !important;
}

/*  */
/* RechargeRecord CSS */

.recordBox {
  border-bottom: 1px solid gray;
  padding: 15px;
  background: white;
}

.recordText {
  display: flex;
  justify-content: space-between;
}

.recordText h5 {
  margin-top: 6px;
  margin-bottom: 6px;
  color: gray;
  font-size: 14px;
  font-weight: 600;
}

.redText h5 {
  color: red;
}

.grayText h5 {
  color: gray;
}

/*  */
/* Promotion CSS */

.proFlex {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: 40px;
}

.proTxt h4:first-of-type {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--main-color);
}

.proTxt h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  margin-top: 3px;
  color: var(--main-color);
}

.proInputbox {
  padding: 20px;
}

.proInput {
  margin-top: 20px;
  margin-bottom: 5px;
}

.proInput h4 {
  font-size: 12px;
  color: var(--main-color);
}

.proInput h5 {
  border-bottom: 1px solid var(--main-color);
  font-size: 14px;
  background: none;
  padding: 2px 0;
}

.proBtn {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.proBtn button {
  width: 100%;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  /* background: #f5f5f5; */
  background: var(--main_gradient-color2);
  color: #fff;
  border: none;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.tabsTable {
  margin-bottom: 10%;
}

.allProtabs {
  display: grid;
  grid-template-columns: 50fr 50fr;
  text-align: center;
  margin-top: 25px;
  border-top: 1px solid rgb(199, 199, 199);
}

.clickTabs h4 {
  color: white;
  /* background: rgb(219, 219, 219); */
  background: var(--main_gradient-color2);
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
}

.nonclickTabs h4 {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
}

/*  */
/* React Carousel */

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 1.3rem;
}

.carousel-indicators {
  display: none !important;
}

.carousel {
  position: static !important;
}

.carousel-item {
  position: static !important;
}

.carousel-inner {
  position: static !important;
}

.carousel-control-prev {
  display: none !important;
}

.carousel-control-prev-icon {
  display: none !important;
}

.carousel-control-next {
  display: none !important;
}

.carousel-control-next-icon {
  display: none !important;
}

/*  */

.css-12whjd8 {
  width: 250px !important;
  padding: 28px !important;
}

.Toastify__toast-theme--light {
  height: 72px !important;
}

.Toastify__toast-body>div:last-child {
  text-wrap: nowrap !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.redColor,
.redColor h5 {
  color: var(--main-color);
  /* color: rgb(250, 46, 46); */
  /* background-color: #fff; */
  /* padding: 5px; */
  font-size: 16px;
  font-weight: 700;
}

.redColorrr,
.redColorrr h5 {
  color: var(--main-color);
  /* color: rgb(250, 46, 46); */
  /* background-color: #fff; */
  /* padding: 5px; */
  font-size: 16px;
  font-weight: 700;
}

.orangeColor,
.orangeColor h5 {
  /* color: orange; */
  color: #fff;
  font-size: 12px;
}

.greenColor,
.greenColor h5 {
  color: green;
  /* color: green; */
  font-size: 16px;
  font-weight: 700;
}

.greenColorrr,
.greenColorrr h5 {
  /* color: greenyellow; */
  color: green;
  font-size: 16px;
  font-weight: 700;
}

/*  */
/* BankCard CSS */

.bankBox {
  display: grid;
  grid-template-columns: 10fr 80fr 10fr;
  padding: 13px 10px;
  margin-bottom: 15px;
  background: white;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.bankIcon i {
  font-size: 18px;
  color: white;
  background: rgb(175, 175, 175);
  border-radius: 32px;
  padding: 10px;
  margin-top: 2px;
}

.bankTxt {
  margin-left: 10px;
}

.bankTxt h4:first-of-type {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: black;
}

.bankTxt h4 {
  color: gray;
  margin-top: 2px;
}

.bankIcon2 h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: white;
  background: rgb(175, 175, 175);
  border-radius: 32px;
  text-align: center;
  padding: 7px 0px;
  margin-top: 3px;
}

.bankPopup {
  padding: 25px;
}

.bankPopup h5 {
  margin-top: 0;
  margin-bottom: 20px;
  color: gray;
}

.bankPopup h4 {
  margin-top: 20px;
  margin-bottom: 0;
}

.bankPopup h4 i {
  margin-right: 10px;
  font-size: 14px;
}

/*  */
/* Mui Table Pagination & Accordian CSS */

.css-1d550nw.Mui-selected {
  color: white !important;
}

.css-1d550nw {
  color: white !important;
}

.css-9hfghl {
  background: #373636 !important;
}

.proBar .css-u7qq7e {
  /* background: #2b3270 !important; */
  background: var(--main_gradient-color2) !important;
}

.avAccordian .css-u7qq7e {
  background: #373636 !important;
}

.css-u7qq7e {
  background: white !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  border: none !important;
  box-shadow: none !important;
  width: 100% !important;
  padding-left: 4.5% !important;
  padding-right: 6.5% !important;
  position: static !important;
}

.css-1b1uphw-MuiTypography-root {
  font-weight: 500 !important;
  font-family: futura !important;
  color: white !important;
}

.css-73itzv-MuiTypography-root {
  color: white !important;
  font-weight: 500 !important;
  font-family: futura !important;
}

.css-ns5zve-MuiTypography-root {
  font-weight: 500 !important;
  font-family: futura !important;
}

.css-18mgdz6 {
  color: black !important;
  font-size: 17px !important;
}

.css-j7olq8 {
  color: black !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}

.css-67l5gl {
  box-shadow: none !important;
}

.css-q34dxg {
  font-size: 1.175rem !important;
  padding: 5px !important;
  text-align: center !important;
  /* background: #22275b !important;
  color: white !important; */
  background: #fff !important;
  /* color: white !important; */
}

.css-1i36psw {
  font-size: 1.175rem !important;
  padding: 3px !important;
  text-align: center !important;
  /* color: #fff !important; */
  /* background: #22275b !important; */
  background: #ffffff !important;
}

.css-1bigob2 {
  font-size: 1.175rem !important;
  padding: 6px 18px !important;
  text-align: center !important;
  /* background: #2b3270 !important; */
  background: var(--main_gradient-color2) !important;
  color: white !important;
}

.css-1sgp6db {
  font-size: 1.175rem !important;
  text-align: center !important;
  /* background: #2b3270 !important; */
  background: var(--main_gradient-color2) !important;
  color: white !important;
}

.css-8nphli {
  overflow-x: hidden !important;
  padding-left: 4px !important;
}

.css-8nphli .MuiTablePagination-actions {
  margin-left: 5px !important;
}

.css-1foz6sg {
  color: white !important;
  font-weight: 500 !important;
  font-family: futura !important;
}

.css-11i759w {
  font-weight: 500 !important;
  font-family: futura !important;
}

.css-1edj5e7 {
  font-weight: 500 !important;
  font-family: futura !important;
}

.css-15wwp11-MuiTableHead-root {
  background: #22275b !important;
}

.css-9l5vo-MuiCollapse-wrapperInner {
  padding-top: 5px !important;
  padding-bottom: 10px !important;
  background: white !important;
}

/*  */
/* React Tabs */

.tabHome {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 5px;
}

.tabHome ol,
ul {
  padding-left: 0 !important;
}

.tabIcon {
  text-align: center;
}

.tabIcon i {
  font-size: 18px;
}

.tabIcon svg {
  vertical-align: baseline !important;
}

.tabIcon h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  white-space: nowrap;
}

.react-tabs__tab {
  border: none !important;
}

.react-tabs__tab--selected {
  border: none !important;
  color: #0081ff !important;
}

.react-tabs__tab-panel--selected {
  margin-top: -10px !important;
}

/*  */

.activeTab {
  color: var(--main-color);
}

.expandIcon i {
  color: black;
  font-size: 14px;
}

.expandIcon2 i {
  color: white;
  font-size: 14px;
}

.audio {
  display: none;
}

/*  */

.css-67l5gl {
  position: static !important;
}

.css-1yp79u1 {
  position: static !important;
}

.css-bggqda.Mui-selected {
  color: white !important;
}

.css-bggqda {
  color: white !important;
}

/*  */
/* FloatButton CSS */

.telBtn i {
  position: fixed;
  right: 8px;
  bottom: 65px;
  font-size: 40px;
  color: blue;
  background: white;
  border-radius: 20px;
}

/*  */
/* Aviator CSS */

.gameCont {
  padding: 10px;
  background: black;
}

.slideHistory {
  font-weight: 600;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.allgameHistory {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  column-gap: 8px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.gameHistory {
  margin: 3px;
  padding: 3px 4px;
  text-align: center;
  color: white;
  border-radius: 12px;
  border: 1px solid rgb(197, 197, 197);
}

.gameHistory h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
}

.gameInfo {
  margin-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.loadingUi {
  position: relative;
  text-align: center;
  height: 200px;
}

.loadingUi img {
  width: 100%;
  max-height: 200px;
}

.loadingUi h1 {
  position: absolute;
  left: 35%;
  bottom: 10%;
  color: white;
  font-size: 44px;
  font-weight: 700;
}

.loadingUi h2 {
  padding-top: 55px;
  color: white;
  font-size: 36px;
  font-weight: 700;
}

.loadingUi h3 {
  color: rgb(255, 246, 120);
  font-size: 46px;
  font-weight: 700;
}

/* .loadingUi h4 {
  position: absolute;
  left: 28%;
  bottom: 1%;
  color: white;
  font-size: 14px;
  font-weight: 700;
  border: 2px solid black;
} */

.gameFlex {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 20px;
  padding: 15px;
  margin-top: 10px;
  border-radius: 7px;
  background: #373636;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.gameInput {
  display: grid;
  grid-template-columns: 10fr 80fr 10fr;
  width: 100%;
  height: 30px;
  padding: 3px;
  border-radius: 20px;
  color: white;
  background: #000;
}

.gameInput input {
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  color: white;
  background: none;
  border: none;
  border-radius: 22px;
}

.gameInput button {
  color: white;
  background-color: black;
  border: 2px solid gray;
  border-radius: 20px;
}

.gameInput button i {
  font-size: 13px;
}

.gameAmount {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 5px;
  margin: 1px 2px;
}

.gameAmount button {
  color: white;
  background: black;
  margin: 1.5px;
  border: none;
  border-radius: 10px;
}

.submitBtn button {
  width: 100%;
  padding: 22px 10px;
  height: 76px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 20px;
  color: white;
  background: rgb(19, 212, 19);
}

.orangeBtn button {
  width: 100%;
  height: 76px;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 20px;
  color: white;
  background: orange;
}

.orangeBtn h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.disableBtn button {
  width: 100%;
  height: 76px;
  padding: 12px;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 20px;
  color: white;
  background: rgb(201, 201, 201);
}

.disableBtn h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.gamePeriod {
  margin-top: 10px;
  padding: 12px;
  color: white;
  background: #373636;
  border-radius: 7px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.gamePeriod h3 {
  margin-top: 9px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}

.gamePeriod h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
}

.gamePeriod h5 i {
  font-size: 14px;
  padding-right: 6px;
}

.gameTabs .css-heg063-MuiTabs-flexContainer {
  margin-top: 8px !important;
  display: grid !important;
  grid-template-columns: 50fr 50fr !important;
}

.gameTabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  /* background: #2b3270 !important; */
  background: var(--main_gradient-color2) !important;
}

.css-k008qs {
  /* background: #2b3270 !important; */
  background: var(--main_gradient-color2) !important;
  color: white !important;
  text-decoration: none !important;
  margin-top: 8px !important;
  display: grid !important;
  grid-template-columns: 50fr 50fr !important;
}

.css-1q2h7u5.Mui-selected {
  font-size: 13px !important;
  font-weight: 700 !important;
  color: white !important;
}

.css-1q2h7u5 {
  font-size: 13px !important;
  font-weight: 700 !important;
  color: white !important;
}

.css-ttwr4n {
  background-color: white !important;
}

.tableAll table {
  width: 100%;
  text-align: center;
  color: #000;
  /* background: #22275b; */
  background: #fff;
}

.tableAll table tr th {
  font-size: 14px;
  padding: 10px;
  color: #fff;
  background: var(--main_gradient-color2);
}

.tableAll table tr td {
  font-size: 14px;
  padding: 10px
}

.background {
  height: 200px;
  text-align: center;
  overflow: hidden;
  background-color: black;
}

.background h4 {
  position: absolute;
  left: 28%;
  bottom: 1%;
  color: white;
  font-size: 14px;
  font-weight: 700;
  border: 2px solid black;
}

.background svg {
  animation: spin 1s linear infinite;
  margin-top: 40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* .background2 {
  position: relative;
  height: 200px;
  text-align: center;
  background-color: black;
  overflow: hidden;
}

.background2 h1 {
  position: absolute;
  left: 35%;
  bottom: 10%;
  color: white;
  font-size: 44px;
  font-weight: 700;
}

.background2 svg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 90px;
  height: auto;
  animation: fly 3s linear, flyNew 3s linear 4s infinite;
}

@keyframes fly {
  0% {
    left: 0;
    right: auto;
    transform: translate(0, 0) rotate(0deg);
  }

  50% {
    left: 0;
    right: auto;
    transform: translate(280px, -150px) rotate(0deg);
  }
}

@keyframes flyNew {
  0% {
    transform: translate(300, 300) rotate(0deg);
  }

  50% {
    transform: translate(-2px, -150px) rotate(0deg);
  }
} */

.background2 {
  position: relative;
  height: 200px;
  text-align: center;
  background-color: black;
  overflow: hidden;
}

.background2 h1 {
  position: absolute;
  left: 35%;
  bottom: 10%;
  color: white;
  font-size: 44px;
  font-weight: 700;
}

.background2 svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90px;
  height: auto;
  animation: fly 4s linear, flyNew 3s linear 3s infinite;
}

@keyframes fly {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }

  50% {
    transform: translate(280px, -150px) rotate(0deg);
  }
}

@keyframes flyNew {
  0% {
    transform: translate(300, 300) rotate(0deg);
  }

  50% {
    transform: translate(100px, -150px) rotate(0deg);
  }
}

.avRecord {
  display: grid;
  justify-content: center;
  text-align: center;
  padding-top: 23px;
  padding-bottom: 15px;
  margin-bottom: 1px;
  color: white;
  background: #373636;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.avRecord i {
  font-size: 20px;
}

.avRecord h5 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
}

.avTabs .css-heg063-MuiTabs-flexContainer {
  margin-top: 8px !important;
  display: grid !important;
  grid-template-columns: 50fr 50fr !important;
}

.avTabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  background: #373636 !important;
}

.avTabs .css-cnjte8-MuiButtonBase-root-MuiTab-root {
  font-size: 13px !important;
  font-weight: 700 !important;
  color: white !important;
}

.avTabs .css-cnjte8-MuiButtonBase-root-MuiTab-root.Mui-selected {
  font-size: 13px !important;
  font-weight: 700 !important;
  color: white !important;
}

.avTabsinfo .css-1ygcj2i-MuiTableCell-root {
  padding: 0px 5px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: white !important;
  background: black !important;
}

.avTabsinfo .css-1azl6jz-MuiTableCell-root {
  font-size: 12px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: white !important;
  background-color: black !important;
}

.avTabsinfo .css-1ex1afd-MuiTableCell-root {
  padding: 12px 8px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  text-align: center !important;
  border: none !important;
  color: white !important;
  background: black !important;
}

.avTabsinfo .css-177gid-MuiTableCell-root {
  padding: 5px 2px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  text-align: center !important;
  border: none !important;
  color: white !important;
  background: black !important;
}

.avBtn {
  background: black;
  text-align: right;
}

.avBtn button {
  background: black;
  color: white;
  border: none;
}

.avBtn button i {
  font-size: 16px;
  padding: 8px 10px;
}

.slideHistory .css-1t91zga-MuiButtonBase-root-MuiAccordionSummary-root {
  background: #373636 !important;
  min-height: 20px !important;
}

.slideHistory .css-9l5vo-MuiCollapse-wrapperInner {
  padding-top: 5px !important;
  padding-bottom: 10px !important;
  background: black !important;
}

.whiteText {
  position: absolute;
  top: 0;
  width: 100%;
  height: 200px;
}

.whiteText h4 {
  position: absolute;
  left: 0;
  color: white;
  animation: scroll-up 2s linear infinite;
}

.whiteText h4 i {
  font-size: 5px;
}

@keyframes scroll-up {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}

.botScroll {
  position: absolute;
  top: 0;
  width: 100%;
  height: 200px;
}

.botScroll h4 {
  position: absolute;
  bottom: -13px;
  color: white;
  animation: scroll-left 3s linear infinite;
}

.botScroll h4 i {
  font-size: 5px;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(-100%);
  }
}

/*  */

.sliderImg {
  /* margin: 10px; */
}

.sliderImg img {
  width: 100%;
  height: 170px;
  /* border-radius: 7px */
}

.allgameBox {
  background: #22275b;
  padding: 10px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.gameHead>h1:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 0.2rem;
  height: 1.4rem;
  background: var(--main-color);
}
*, ::after, ::before {
  box-sizing: border-box;
}
.gameHead>h1 {
  position: relative;
  margin-top: 30px;
  margin-bottom: 1.34667rem;
  padding-left: 0.5rem;
  font-size: 1.48rem;
  font-weight: 700;
  margin-left: 10px;
  color: var(--darkTextW, var(--text_color_L1));
}

.gameBox {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 15px;
  margin: 15px 0;
}

.threeGames {
  display: grid;
  grid-template-columns: 33fr 33fr 33fr;
  column-gap: 15px;
  margin: 15px 0;
}

/* .gameCard {
   padding: 10px; 
   text-align: center; 
   background: white; 
   border: 4px solid black; 
   box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
} */

.gameCard img {
  width: 100%;
  height: 136px;
  border-radius: 10px;
  border: 2px solid white;
}

/* .gameCard h4 {
  margin-top: 10px;
  margin-bottom: 0;
  color: black;
} */

.winnerbox {
  padding-top: 50px;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  color: white;
}

.winnerbox img {
  width: 100%;
}

.winuser1 img {
  position: absolute;
  top: 0;
  left: 10px;
  right: 0;
  width: 80px;
  height: 80px;
  border: 2px solid white;
  border-radius: 100px;
}

.winuser1 h4 {
  position: absolute;
  bottom: 20px;
  left: 30px;
  right: 0;
}

.winuser2 img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid white;
  border-radius: 100px;
}

.winuser2 h4 {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
}

.winuser3 img {
  position: absolute;
  top: 0;
  right: 15px;
  width: 80px;
  height: 80px;
  border: 2px solid white;
  border-radius: 100px;
}

.winuser3 h4 {
  position: absolute;
  bottom: 20px;
  right: 30px;
}

/*  */

.ludoCont {
  margin-top: 70px;
}

.diceImg {
  display: flex;
  justify-content: space-between;
  margin: 10px 5px;
}

.diceImg img {
  padding: 6px;
  height: 55px;
  border-radius: 8px;
}

.ludoFlex {
  display: grid;
  grid-template-columns: 40fr 20fr 40fr;
}

.ludobox {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-around;
  padding: 0 7px;
  column-gap: 10px;
}

.ludocircle {
  width: 40px;
  height: 40px;
  margin: 15px 0;
  padding: 20px;
  border-radius: 30px;
}

.ludoMid {
  display: grid;
  grid-template-columns: 40fr 20fr 40fr;
}

.ludoMid img {
  width: 100%;
  height: 78px;
}

.ludoPath {
  display: grid;
  grid-template-columns: auto auto auto;
}

.ludoPath2 {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
}

.ludoCell {
  border: 1px solid black;
}

.ludoCell2 {
  padding: 12px;
  border: 1px solid black;
}

/*  */

/* #main {
  width: 1000px;
  float: left;
}

.pawns {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  position: absolute;
}

#board {
  background: url("../public/board.gif") no-repeat;
  background-size: cover;
  width: 650px;
  height: 550px;
  float: left;
  margin-top: 8px;
}

#uselesstext1 {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}

footer {
  font-size: larger;
  color: darkred;

} */

/* #dice { */
/* background-image: "url(" ../public/dice.gif")" */
/* background-image: url("../public/board.gif") no-repeat; */
/* background-size: 'contain',
    width: '150px',
    height: '150px',
    float: 'left' */
/* } */

/* Dice CSS */

.diceInfo {
  padding: 20px 15px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.diceBox {
  display: flex;
  justify-content: space-between;
}

.dicePeriod {
  /* color: white; */
  color: var(--main-color);
  margin-top: 6px;
}

.dicePeriod h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
}

.dicePeriod h3 {
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 21px;
  font-weight: 500;
}

.diceCount h5 {
  margin-top: 0;
  margin-bottom: 0;
  /* color: white; */
  color: var(--main-color);
  font-size: 15px;
  text-align: right;
}

.diceCount h3 {
  margin-top: 8px;
}

.diceCount h3 span {
  margin: 0 4px;
  /* color: black; */
  color: var(--main-color);
  padding: 2px 5px;
  font-size: 24px;
  background: rgb(228, 228, 228);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-top-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.diceAllCard {
  display: flex;
  justify-content: space-around;
  padding: 15px;
  color: white;
  /* background: #2b3270; */
  background: var(--main_gradient-color2);
  border-radius: 8px;
  text-align: center;
  margin-top: 18px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.diceBtn button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  color: white;
  /* background: blue; */
  background: var(--main_gradient-color2);
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 8px;
}

.diceRecordBox {
  margin-top: 15px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.diceRecordHead {
  padding: 10px;
  text-align: center;
  color: white;
  /* background: #2b3270; */
  background: var(--main_gradient-color2);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.diceRecordHead h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
}

.diceRecord {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
  justify-content: space-between;
  width: 100%;
  padding: 20px 10px;
}

.diceRecord h4 {
  color: white;
  text-align: center;
  padding: 6px;
  border-radius: 25px;
}

.diceOne {
  margin: 7px 0;
}

.diceOne h4 {
  width: 28px;
  text-align: center;
  padding: 6px;
  margin: 2px;
  font-size: 14px;
  color: white;
  /* background: #2b3270; */
  background: var(--main_gradient-color2);
  border: 1px solid gainsboro;
  border-radius: 20px;
}

.diceOne h5 {
  text-align: center;
  color: var(--main-color);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
}

.diceTable table {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.diceTable table tr th {
  color: #000;
  font-size: 13px;
}

.diceTable table tr td {
  font-weight: 600;
  font-size: 12px;
  padding: 8px;
  color: #000000;
}

.diceTable table tr td span {
  margin: auto;
  padding: 6px;
  width: fit-content;
  color: white;
  border-radius: 25px;
  /* background: #2b3270; */
  background: var(--main_gradient-color2);
}

.diceTabs .css-heg063-MuiTabs-flexContainer {
  display: grid !important;
  grid-template-columns: 50fr 50fr !important;
  border-radius: 5px !important;
  border: 1px solid gray;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

.diceTabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  /* background: #2b3270 !important; */
  background: var(--main_gradient-color2) !important;
  color: white !important;
  /* box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important; */
}

.diceTabs .css-1aquho2-MuiTabs-indicator {
  background: white !important;
}

.css-1862zle {
  margin-top: 15px !important;
}

.diceBlueHead h2 {
  /* background: black; */
  background: var(--main_gradient-color2);
  color: white;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  font-weight: 400;
}

.diceResult {
  text-align: center;
}

.diceResult h2 {
  margin-top: 0;
  margin-bottom: 0;
  padding: 10px;
  font-weight: 800;
  color: yellow;
  background: orange;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  font-size: 32px;
}

.diceResult h3 {
  margin: 16px auto;
  width: fit-content;
  padding: 10px 12px;
  color: white;
  background: blue;
  border-radius: 40px;
  font-size: 32px;
}

.diceOutput {
  display: flex;
  justify-content: space-between;
  padding: 1px 10px;
}

.diceAllout {
  margin: 10px;
  padding: 10px 2px;
  border-radius: 5px;
  border: 1px solid aqua;
  background: azure;
}

.diceClose {
  margin: 15px 10px;
}

.diceClose button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  color: white;
  background: blue;
  border: none;
  border-radius: 7px;
}

.diceRule {
  /* background: #0A1846; */
  background: var(--main_gradient-color2);
  color: white;
  text-align: center;
  padding: 10px;
}

.diceRuleInfo {
  padding: 10px;
}

.diceRuleInfo h4 {
  color: gray;
  font-size: 13px;
}

.diceRuleInfo button {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  padding: 5px;
  margin-top: 15px;
  color: white;
  /* background: blue; */
  background: var(--main_gradient-color2);
  border: none;
  border-radius: 5px;
}

.css-goitey {
  /* color: white !important; */
  color: var(--main-color) !important;
}

.css-mcxtpx {
  /* color: white !important; */
  color: var(--main-color) !important;
}

.css-1tfve6q-MuiSlider-mark {
  background: none !important;
  width: 7px !important;
  height: 11px !important;
}

.css-6cwnna-MuiSlider-mark {
  background: none !important;
}

.css-ltlhnc-MuiSlider-root {
  color: white !important;
  height: 14px !important;
}

.css-1ucwjgd-MuiSlider-markLabel {
  /* color: white !important; */
  color: var(--main-color) !important;
}

.css-1bvr8oc-MuiSlider-markLabel {
  /* color: white !important; */
  color: var(--main-color) !important;
}

/*  */
/* AndarBahar CSS */

.andarInfo {
  padding: 20px 15px;
  /* background: #2b3270; */
  background: var(--main_gradient-color2);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.andarBox {
  display: flex;
  justify-content: space-between;
}

.andarPeriod {
  margin-top: 6px;
  color: white;
}

.andarPeriod h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
}

.andarPeriod h3 {
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 21px;
  font-weight: 500;
}

.andarCount {
  color: white;
}

.andarCount h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  text-align: right;
}

.andarCount h3 {
  margin-top: 8px;
}

.andarCount h3 span {
  margin: 0 4px;
  color: black;
  padding: 2px 5px;
  font-size: 24px;
  background: white;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-top-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.andarSingle {
  width: 99px;
  height: 140px;
  background: white;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}

.andarSingle img {
  width: 99px;
  height: 140px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.andarFlex {
  display: flex;
  justify-content: center;
  column-gap: 15px;
  margin-top: 15px;
}

.andarTxt {
  width: 99px;
  height: 140px;
  padding: 20px;
  background: white;
  border-radius: 10px;
}

.andarTxt h4 {
  margin-top: 50%;
  color: rgb(173, 172, 172);
  font-weight: 800;
  font-size: 18px;
}

.andarImg {
  position: relative;
}

.andarImg img {
  width: 112px;
  height: 140px;
  border-radius: 10px;
}

.andarImg2 img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.topAnimate {
  animation: moveTop 1s forwards;
}

@keyframes moveTop {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc(27vw - 252px));
  }
}

.leftAnimate {
  animation: moveLeft 1s forwards;
}

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(38vw - 252px));
  }
}

.rightAnimate {
  animation: moveRight 1s forwards;
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(100vw - 252px));
  }
}

.andarAllBtn {
  display: grid;
  grid-template-columns: 33fr 33fr 33fr;
  column-gap: 10px;
  margin-top: 25px;
}

.andarBtn button {
  width: 100%;
  padding: 15px 20px;
  font-weight: 600;
  font-size: 17px;
  border: none;
  border-radius: 6px;
  color: white;
  background: #0093ff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.tieBtn button {
  width: 100%;
  padding: 15px 20px;
  font-weight: 600;
  font-size: 17px;
  border: none;
  border-radius: 6px;
  color: white;
  background: #ffa33b;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.baharBtn button {
  width: 100%;
  padding: 15px 20px;
  font-weight: 600;
  font-size: 17px;
  border: none;
  border-radius: 6px;
  /* color: white; */
  color: black;
  /* background: red; */
  background: greenyellow;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

/*  */

.mineCont {
  /* background: #000; */
  background: #fff;
}

.mineInfo {
  padding: 10px;
  background: black;
}

.mineFlex {
  display: flex;
  justify-content: space-between;
  padding: 7px;
}

.mineTime {
  display: flex;
  gap: 5px;
  padding-top: 2px;
}

.mineTime i {
  color: azure;
  font-size: 24px;
}

.mineTime h2 {
  color: white;
}

.mineMoney {
  color: white;
}

.mineMoney span {
  font-size: 20px;
  padding-right: 4px;
}

.mineFourBox {
  display: grid;
  grid-template-columns: 25fr 25fr 25fr 25fr;
  column-gap: 10px;
  margin-top: 10px;
  position: relative;
}

.mineFour {
  position: relative;
  justify-self: center;
  width: 100%;
  height: 82px;
  margin: 5px 0;
  background: #636060;
  border-radius: 12px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mineFour img {
  position: absolute;
  top: 18px;
  left: 0;
  right: 0;
  margin: auto;
  width: 70px;
}

.mineFour h4 {
  position: absolute;
  left: 0;
  right: 0;
  width: fit-content;
  margin: auto;
  padding: 5px;
  color: red;
  font-weight: 700;
  font-size: 18px;
}

.bombFour img {
  position: absolute;
  top: 0;
  width: 75px;
}

.mineAllBox {
  display: grid;
  grid-template-columns: 30fr 30fr 30fr;
  column-gap: 10px;
  margin-top: 10px;
  position: relative;
}

.mineBox {
  position: relative;
  justify-self: center;
  width: 100%;
  height: 110px;
  margin: 5px 0;
  background: #636060;
  border-radius: 12px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mineBox img {
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90px;
}

.mineBox h4 {
  position: absolute;
  left: 0;
  right: 0;
  width: fit-content;
  margin: auto;
  padding: 8px;
  color: red;
  font-weight: 700;
  font-size: 22px;
}

.bombImg img {
  position: absolute;
  top: 0;
  width: 100px;
}

.mineStart {
  position: absolute;
  left: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
  animation: zoominoutsinglefeatured 1s infinite;
}

.mineStart h2 {
  margin: auto;
  padding: 35px 0;
  text-align: center;
  width: 120px;
  height: 120px;
  font-size: 36px;
  font-weight: 700;
  color: white;
  background: rgb(18, 206, 18);
  border-radius: 100px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.mineCash button {
  width: 100%;
  margin-top: 15px;
  padding: 15px;
  font-weight: 600;
  font-size: 20px;
  color: white;
  background: orange;
  border: none;
  border-radius: 12px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background: none !important;
}

.mineNav {
  display: flex;
  padding: 10px;
  background: rgb(129 126 126);
  color: white;
  height: 52px;
}

.mineNav i:first-of-type {
  font-size: 20px;
  padding-top: 4px;
  position: absolute;
  left: 13px;
}

.mineNav i {
  font-size: 24px;
  padding-top: 2.5px;
  position: absolute;
  right: 15px;
}

.mineNav h3 {
  padding-top: 4px;
  margin-left: 40px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
}

.mineNav h4 {
  position: absolute;
  right: 55px;
  padding-top: 4px;
  margin-left: 40px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
}

.mineHeader {
  padding: 10px;
  text-align: center;
  color: white;
  /* background: black; */
  background: var(--main_gradient-color2);
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mineHeader h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
}

.minePage {
  text-align: right;
  /* background: black; */
  background: var(--main_gradient-color2);
  border-top: 2px solid white;
}

.minePage button {
  /* background: black; */
  background: var(--main_gradient-color2);
  color: white;
  border: none;
}

.minePage button i {
  font-size: 16px;
  padding: 8px 10px;
}

.mineTabs .css-heg063-MuiTabs-flexContainer {
  display: grid !important;
  grid-template-columns: 50fr 50fr !important;
  border-radius: 5px !important;
  border: 1px solid gray;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

.mineTabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  background: black !important;
  color: white !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

.mineTabs .css-1aquho2-MuiTabs-indicator {
  background: white !important;
}

.mineTabs .css-1fxmlng-MuiButtonBase-root-MuiTab-root {
  color: white !important;
  font-size: 13px !important;
  font-weight: 700 !important;
}

.mineTabs .css-1fxmlng-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: white !important;
  font-size: 13px !important;
  font-weight: 700 !important;
}

/*  */

.pattiCont {
  /* position: relative; */
  display: grid;
  grid-template-columns: 20fr 80fr;
  /* border: 1px solid red; */
}

.tableImg {
  width: 330px;
  /* height: 100%; */
  position: relative;
  margin-left: auto;
  margin-right: auto;
  /* border: 1px solid; */
}

.tableImg img {
  width: 100%;
  height: fit-content;
  /* padding-left: 40px; */
  /* position: relative; */
  /* margin-left: auto; */
}

.girlImg img {
  position: absolute;
  top: 240px;
  right: 0;
  width: 75px;
  border-radius: 10px;
  transform: rotate(90deg);
}

.girlAmount {
  display: flex;
  background: #000;
}

.girlAmount img {
  width: 25px;
  position: absolute;
  top: 245px;
  right: 80px;
}

.girlAmount h4 {
  margin-top: 0;
  margin-bottom: 0;
  position: absolute;
  top: 280px;
  right: 75px;
  color: white;
  font-size: 20px;
  transform: rotate(90deg);
}

.menImg1 img {
  position: absolute;
  top: 45px;
  right: 10px;
  width: 100px;
  border-radius: 5px;
  border-top-left-radius: 500px;
  border-bottom-left-radius: 200px;
  border-top-right-radius: 200px;
  border-bottom-right-radius: 500px;
  transform: rotate(90deg);
}

.menImg2 img {
  position: absolute;
  top: 55px;
  left: 20px;
  width: 100px;
  border-radius: 5px;
  border-top-left-radius: 40px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 40px;
  transform: rotate(90deg);
}

.menImg3 img {
  position: absolute;
  bottom: 55px;
  right: 10px;
  width: 100px;
  border-radius: 5px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 40px;
  transform: rotate(90deg);
}

.menImg4 img {
  position: absolute;
  bottom: 55px;
  left: 25px;
  width: 100px;
  border-radius: 5px;
  border-top-left-radius: 100px;
  border-top-right-radius: 180px;
  border-bottom-left-radius: 180px;
  border-bottom-right-radius: 100px;
  transform: rotate(90deg);
}

.tableBtns {
  /* display: flex; */
  border: 7px blue;
  /* position: absolute; */
}

.tableBtns button {
  padding: 10px;
  height: fit-content;
  text-wrap: nowrap;
  font-size: 14px;
  font-weight: 600;
  color: white;
  border: 7px blue;
  /* transform: rotate(90deg); */
}

.load1 {
  position: absolute;
  top: 40px;
  right: 22px;
}

.load2 {
  position: absolute;
  top: 36px;
  left: 20px;
}

.load3 {
  position: absolute;
  bottom: 45px;
  right: 8px;
}

.load4 {
  position: absolute;
  bottom: 53px;
  left: 10px;
}

.see1 img {
  position: absolute;
  top: 155px;
  right: 35px;
  width: 50px;
  border-radius: 10px;
  transform: rotate(90deg);
}

.see2 img {
  position: absolute;
  top: 155px;
  left: 40px;
  width: 50px;
  border-radius: 10px;
  transform: rotate(90deg);
}

.see3 img {
  position: absolute;
  bottom: 160px;
  right: 35px;
  width: 50px;
  border-radius: 10px;
  transform: rotate(90deg);
}

.see4 img {
  position: absolute;
  bottom: 163px;
  left: 40px;
  width: 50px;
  border-radius: 10px;
  transform: rotate(90deg);
}

.chaal1 h4 {
  position: absolute;
  top: 158px;
  right: 48px;
  padding: 3px 6px;
  width: fit-content;
  color: white;
  background: red;
  text-wrap: nowrap;
  font-size: 15px;
  border-radius: 10px;
  transform: rotate(90deg);
}

.chaal2 h4 {
  position: absolute;
  top: 162px;
  left: 55px;
  padding: 3px 6px;
  width: fit-content;
  color: white;
  background: red;
  text-wrap: nowrap;
  font-size: 15px;
  border-radius: 10px;
  transform: rotate(90deg);
}

.chaal3 h4 {
  position: absolute;
  bottom: 170px;
  right: 45px;
  padding: 3px 6px;
  width: fit-content;
  color: white;
  background: red;
  text-wrap: nowrap;
  font-size: 15px;
  border-radius: 10px;
  transform: rotate(90deg);
}

.chaal4 h4 {
  position: absolute;
  bottom: 180px;
  left: 53px;
  padding: 3px 6px;
  width: fit-content;
  color: white;
  background: red;
  text-wrap: nowrap;
  font-size: 15px;
  border-radius: 10px;
  transform: rotate(90deg);
}

.winnerUser {
  position: absolute;
  top: 260px;
  left: 0;
  right: 0;
  width: fit-content;
  margin: auto;
  padding: 5px 12px;
  background: red;
  border-radius: 12px;
  transform: rotate(90deg);
}

.winnerUser h4 {
  color: yellow;
  font-weight: 800;
  font-size: 22px;
}

.uuu {
  margin-top: 30px;
}

.sss {
  margin-bottom: 40px;
  text-align: center;
  /* border: 2px solid red; */
}

.sss h4 {
  width: 0;
  padding: 6px;
  display: inline-block;
  transform: rotate(90deg);
  text-wrap: nowrap;
}

.sss h4 span {
  padding: 10px;
  color: white;
  background: #000;
}

.mainPlayer img {
  position: absolute;
  top: 240px;
  left: 10px;
  width: 80px;
  border-radius: 50px;
  transform: rotate(90deg);
}

.mainloader {
  position: absolute;
  top: 240px;
  left: 0;
}

.mainUser img {
  position: absolute;
  top: 240px;
  left: 10px;
  width: 80px;
  border-radius: 50px;
  color: white;
  transform: rotate(90deg);
}

.mainSee img {
  position: absolute;
  top: 265px;
  left: 100px;
  width: 50px;
  border-radius: 10px;
  transform: rotate(90deg);
}

.mainChaal h4 {
  position: absolute;
  top: 265px;
  left: 60px;
  padding: 3px 6px;
  width: fit-content;
  color: white;
  background: red;
  text-wrap: nowrap;
  font-size: 15px;
  border-radius: 10px;
  transform: rotate(90deg);
}

/*  */
.wintopbox {
  background: #fff;
  border-radius: 15px;
  padding: 20px 5px;
  margin: 12px 10px 40px;
  color: #000;
  text-align: center;
}

.wintopbal h3 {
  font-weight: 600;
  font-size: 17px;
}

.wintopbtn {
  display: flex;
  justify-content: space-around;
  margin-top: 22px;
  /* position: relative; */
}

.wallet-container {
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  align-items: center; /* Centers the content vertically */
}

.wallet-balance {
  display: flex;
  align-items: center; /* Aligns the items vertically */
}

.wallet-icon {
  width: 20px; /* Adjust the size of the image */
  height: 20px; /* Adjust the size of the image */
  margin-right: 5px; /* Adjust space between the image and the text */
}

.btn1 button {
  background: #d23838;
  color: white;
  border: none;
  border-radius: 18px;
  padding: 8px 18px;
  font-weight: 600;
  font-size: 14px;
  margin-right: 12px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.btn2 button {
  background: #17b15e;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 18px;
  font-weight: 600;
  font-size: 14px;
  margin-right: 12px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.clickedColor button {
  background: #d5d5d5 !important;
}

.bigsmall {
  display: grid;
  grid-template-columns: 50fr 50fr;
  width: 80%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.bigsmall1 button {
  width: 100%;
  border: none;
  padding: 6px;
  font-size: 14px;
  font-weight: 700;
  color: white;
  background: #dd9138;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.bigsmall2 button {
  width: 100%;
  border: none;
  padding: 6px;
  font-size: 14px;
  font-weight: 700;
  color: white;
  background: #5088d3;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.hideParent {
  padding: 2px 0;
  position: relative;
}

/* By Happy */

  .product-conatiner-color-prediction {
      width: 100%;
      margin-left: 0;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  background: url('../public/happy/GameBg1.png') no-repeat;
}

  .product-conatiner-avaitor {
      width: 100%;
      margin-left: 0;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  background: url('../public/happy/GameBg2.png') no-repeat;
}

  .product-conatiner-lucky-card {
      width: 100%;
      margin-left: 0;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  background: url('../public/happy/GameBg3.png') no-repeat;
}

  .product-conatiner-dice {
      width: 100%;
      margin-left: 0;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  background: url('../public/happy/GameBg4.png') no-repeat;
}

  .product-conatiner-andar-bahar {
      width: 100%;
      margin-left: 0;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  background: url('../public/happy/GameBg5.png') no-repeat;
}

  .product-conatiner-mine {
      width: 100%;
      margin-left: 0;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  background: url('../public/happy/GameBg6.png') no-repeat;
}

  .product-conatiner-deposit {
      width: 100%;
      border-radius: 10px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
  background: url('../public/happy/DepositeCard.png') no-repeat 100%;
}

  .win_info .win_item {
      width: 100%;
      margin-left: 0;
  background: #f4f5f8;
  color: #000;
  font-size: .72rem;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 5px -3px #000;
  margin-left: 0;
}

.win_info .win_item .left {
  margin-left: 1.32rem;
  display: flex
;
  flex-direction: row;
  align-items: center;
}
* {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
*, ::after, ::before {
  box-sizing: border-box;
}

div {
  display: block;
  unicode-bidi: isolate;
}

.win_info .win_item .right {
  margin-right: 1.32rem;
}
* {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
*, ::after, ::before {
  box-sizing: border-box;
}

div {
  display: block;
  unicode-bidi: isolate;
}

.win_info .win_item .left img {
  width: 2.3rem;
  height: 2.3rem;
  margin-right: .29333rem;
}

* {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
*, ::after, ::before {
  box-sizing: border-box;
}
user agent stylesheet
img {
  overflow-clip-margin: content-box;
  overflow: clip;
}


/* GameGrid.css */

/* Container styling */
.game-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* gap: 20px; */
  justify-items: center;
  padding-top: 10px;
}

/* Card styling */
.game-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Image styling */
.game-image {
  width: 6rem;
  height: 6rem;
  /* border-radius: 50%; */
  object-fit: cover;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.game-image:hover {
  transform: scale(1.1);
}

.first-game-card .game-image {
  background: linear-gradient(180deg, #ce0204, #f2403a);
  padding: 5px;
  border-radius: 10px;
}

/* Title styling */
.game-title {
  font-size: 12px;
  /* font-weight: bold; */
  color: #000;
}

/* GameGrid.css */

/* Container styling */
.wallet-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-items: center;
  padding-top: 10px;
}

/* Card styling */
.wallet-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Image styling */
.wallet-image {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.wallet-image:hover {
  transform: scale(1.1);
}

/* Title styling */
.wallet-title {
  font-size: 12px;
  /* font-weight: bold; */
  color: #000;
}


/* WinnerList.css */

.winner-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px;
  justify-content: center;
  align-items: center;
}

.winner-item {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin: auto; /* Center align */
  position: relative;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.winner-name-container {
  margin-left: 10px;
}

.winner-name {
  font-size: 14px;
  /* font-weight: bold; */
  /* margin: 0; */
  color: #333;
}

.prize-image-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prize-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.winner-info-right {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the text block horizontally */
}

.winner-amount {
  font-size: 12px; /* Adjust font size */
  /* font-weight: bold; */
  color: #000;
  margin: 0; /* Remove any default margin */
}

.winner-label {
  font-size: 10px; /* Slightly larger font */
  color: #888;
  margin: 2px 0 0 0; /* Adjust spacing between the lines */
}



.prize-image-container img {
  width: 70px;
  height: 45px;
  margin-left: 0;
  background: var(--main_gradient-color2);
  border-radius: 10px;
  box-shadow: var(--BoxShadowColor-20);
  -o-object-fit: cover;
  object-fit: cover;
}
img {
  vertical-align: middle;
  border-style: none;
}
* {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
*, ::after, ::before {
  box-sizing: border-box;
}
img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

.wallet-container-header {
  background: var(--light-main_gradient-color, var(--bg_color_L2));
  width: 100%;
  color: #fff;
  padding-bottom: .32rem;
}

.wallet-container-header-belly>div:nth-of-type(2) {
  width: 70%;
  display: flex
;
  justify-content: space-around;
  margin-top: .26667rem;
  text-align: center;
}

.wallet-container-header-belly {
  margin-top: .13333rem;
  padding-top: 2rem;
  display: flex
;
  flex-direction: column;
  align-items: center;
}

.wallet-container-header-belly>div:nth-of-type(2) p {
  margin-bottom: .13333rem;
}
.wallet-container-header-belly .total {
  font-size: 1.5rem;
  font-weight: 500;
}

.wallet-container-header-belly img {
  width: 50px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.grid-item {
  position: relative;
  /* background-color: rgba(240, 240, 240, 0.5); */
  /* border: 1px solid #e0e0e0; */
  /* border-radius: 10px; */
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #555;
  transition: box-shadow 0.3s ease;
  background-size: cover;
  background-position: center;
}

.grid-item:hover {
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); */
}

.highlighted {
  background: var(--main_gradient-color2);
  color: white;
  font-weight: bold;
}

.balance {
  font-size: 10px;
  margin: 0;
}

.name {
  font-size: 12px;
  margin: 5px 0 0;
}



/* GameGrid.css */

/* Container styling */
.payment-method-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  padding-top: 10px;
}

/* Custom background for each active page */
.upi-qr-bg {
  background: var(--main_gradient-color2);
  border-radius: 10px;
}

/* Card styling */
.payment-method-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Image styling */
.payment-method-image {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.payment-method-image:hover {
  transform: scale(1.1);
}

/* Title styling */
.payment-method-title {
  font-size: 12px;
  color: #000;
}

.recharge-btn {
  color: #fff;
  border-radius: 5px;
  font-size: 15px;
  background: var(--main_gradient-color2);
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.d-inline-block {
  display:inline-block;
}
.myinput-group {
  display: flex;
  font-size: 1.15rem;
  border: 2px solid var(--main-color);
  border-radius:3px;
  background: var(--main_gradient-color2);
  cursor: text;
  width: 100%;
  
  & .prepend {
    color: #fff;
    padding: 1rem;
    margin:auto;
  }
  
  & .prependdd {
    color: #fff;
    padding-left: 8px;
    padding-right: 8px;
    margin:auto;
  }
  
  & input {
    padding: .7em 1em;
    border: none;
    color: var(--main-color);
    flex-grow: 1;
    background-color: #f7f7f7;
  }
  
  & .append {
    background: var(--main-color);
    color: #fff;
    border: none;
    padding-left: 1em;
    padding-right: 1em;
    position: relative;
    &::before {
      position: absolute;
      width: 0%;
      height: 100%;
      background-color: #fff;
      content: "";
      transition: 0.3s;
      right: 0;
      bottom: 0;
      opacity: 0;
      border-radius: 0 30px 30px 0;
      left: 0;
      z-index: 0;
    }
    &:hover {
      &::before {
        width: 100%;
        height: 100%;
        opacity: .2;
        border-radius: 0;
      }
    }
    &:active {
      &::before {
        width: 100%;
        height: 100%;
        opacity: .1;
        border-radius: 0;
      }
    }
  }
}

.gradient-text {
  color: var(--main-color)
}

.bgForColorPredictionBettingButtons {
  background-color: #f7eef9;
  border-radius: 10px;
  padding: 10px;
}


.notification-container {
  display: flex;
  align-items: center;
  /* background-color: #ffe5e5; */
  border-radius: 8px;
  padding: 0px 10px;
  width: 100%;
  /* margin-bottom: 10px; */
  font-family: Arial, sans-serif;
}

.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #ffcccc; */
  /* border-radius: 50%; */
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.notification-text {
  font-size: 16px;
  color: black;
  margin-right: auto;
  margin-left: 5px;
}

.arrow {
  fill: black;
  width: 25px;
  height: 25px;
}


.language-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  padding: 0px 10px;
  /* width: ; */
  font-family: Arial, sans-serif;
}

.label {
  font-size: 16px;
  color: black;
  margin-right: auto;
  margin-left: 5px;
}

.value {
  font-size: 12px;
  color: black;
  margin-right: 8px;
}

/* ////////////////////// 08/Jan/2025 \\\\\\\\\\\\\\\\\\\\\ */

.hideBg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 258px;
  background-color: rgba(0, 0, 0, 0.5); /* Dark transparent background */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hideBg h4 {
  color: #fff; /* Light blue text */
  background: var(--main_gradient-color2); /* Deep blue background */
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 100px; /* Adjusted font size */
  text-align: center;
  font-weight: bold;
  margin: 0 5px;
  display: inline-block;
}

.digit-container {
  display: flex;
  gap: 10px; /* Add spacing between digits */
}

.button-gunna-group {
  display: flex;
  gap: 4px; /* Add space between buttons */
  justify-content: flex-end; /* Align buttons to the right */
  position: absolute; /* Enable positioning within the container */
  top: 10px; /* Adjust vertical spacing from the top */
  right: 10px; /* Adjust horizontal spacing from the right */
}

.button-gunna {
  background-color: #eee; /* Default blue color */
  color: #000; /* White text */
  font-family: Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  /* font-weight: bold; */
  padding: 2px 10px; /* Spacing for the button */
  border: none;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-gunna.green-active-gunna {
  background-color: #17b15e;
}

.button-gunna.violet-active-gunna {
  background-color: #9c27b0;
}

.button-gunna.red-active-gunna {
  background-color: #ff0000;
}

.button-gunna.white-active-gunna {
  background: var(--main_gradient-color2);
  color: #fff;
}

.button-gunna.big-active-gunna {
  background: rgb(221, 145, 56);
}

.button-gunna.small-active-gunna {
  background-color: #5088D3;
}

.ColorPredictionButtons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 10px;
  column-gap: 10px;
}

.ColorPredictionButtons button {
  border: 2px solid var(--main-color);
  color: #000;
  padding: 8px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.ColorPredictionButtons .ColorPredictionButtons-active {
  background: var(--main_gradient-color, var(--main_gradient-color));
  font-weight: 600;
  color: #fff;
}
.component-container {
  padding-left: 1px;
  padding-right: 1px;
  padding-bottom: 30px;
  background-color: var(--main-bg-color);
}

.appcolorPrediction {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.transaction-itemcolorPrediction {
  display: flex;
  align-items: center;
  background: var(--main_gradient-color2);
  padding: 12px 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #ffffff;
}

.transaction-itemcolorPrediction.colorPrediction {
  background: var(--main_gradient-color2);
}

.iconcolorPrediction {
  width: 40px;
  height: 40px;
  border-radius: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  color: #ffffff;
  margin-right: 16px;
}

.iconcolorPrediction.smallcolorPrediction {
  background-color: #507dd9;
}

.iconcolorPrediction.smallcolorPrediction.colorPredictioncolorPrediction {
  background-color: #507dd9; /* Prediction styling logic here */
}

.iconcolorPrediction.bigcolorPrediction {
  background-color: #f39c12;
}

.iconcolorPrediction.bigcolorPrediction.colorPredictioncolorPrediction {
  background-color: #f39c12; /* Prediction styling logic here */
}

.detailscolorPrediction {
  flex: 1;
}

.idcolorPrediction {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.timestampcolorPrediction {
  font-size: 12px;
  color: #fff;
  margin: 4px 0 0;
}

.statuscolorPrediction {
  text-align: right;
}

.status-textcolorPrediction {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-block;
}

.status-textcolorPrediction.failedcolorPredictioncolorPrediction {
  color: #ff4d4d;
  border: 2px solid red;
  background-color: rgba(255, 77, 77, 0.1);
}

.status-textcolorPrediction.failedcolorPrediction.colorPredictioncolorPrediction {
  color: #ff4d4d; /* Add prediction color logic */
}

.status-textcolorPrediction.succeedcolorPrediction {
  color: #4caf50;
  background-color: rgba(76, 175, 80, 0.1);
}

.status-textcolorPrediction.succeedcolorPrediction.colorPredictioncolorPrediction {
  color: #4caf50; /* Add prediction color logic */
}

.amountcolorPrediction {
  font-size: 14px;
  font-weight: bold;
  margin: 4px 0 0;
}

.amount.failedcolorPredictioncolorPrediction {
  color: #ff4d4d;
}

.amountcolorPrediction.failedcolorPredictioncolorPrediction.colorPredictioncolorPrediction {
  color: #ff4d4d; /* Add prediction styling */
}

.amountcolorPrediction.succeedcolorPrediction {
  color: #4caf50;
}

.amountcolorPrediction.succeedcolorPrediction.colorPredictioncolorPrediction {
  color: #4caf50; /* Add prediction styling */
}

.ffffffffoooooonnnnntttttsize {
  font-size: 20px;
}

.slidetransaction-itemcolorPrediction {
  cursor: pointer;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #1a2a3a; /* Background color for the main item */
  color: #fff; /* Text color */
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.slidetransaction-itemcolorPrediction:hover {
  background-color: #253b4d; /* Hover background color */
}

.details-slide {
    background: var(--main_gradient-color3);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease;
}

.details-slide.expanded {
    max-height: 300px; /* Adjust based on content */
    padding: 0.75rem;
}

.details-header {
    border-bottom: 1px solid #444;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
}

.details-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.details-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    color: #fff;
}

.details-row span:last-child {
    text-align: right;
    font-weight: bold;
}

.statuscolorPrediction {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.status-textcolorPrediction {
  font-weight: bold;
  margin-bottom: 0.25rem;
  font-size: 11px;
  border: 2px solid;
}

.failedcolorPrediction {
  color: #fa3e3e;
  background-color: #fff;
}

.succeedcolorPrediction {
    color: #5cb85c;
}

.Trend__C {
  width: calc(100% - .69333rem);
  margin: .48rem auto 0;
  text-align: center;
  font-size: .32rem;
}

.Trend__C-head {
  line-height: 1.86667rem;
  background: var(--main_gradient-color); /* Main color */
  border-radius: .33333rem .33333rem 0 0;
  font-weight: 700;
  font-size: larger;
  font-size: .64667rem;
  color: #fff; /* White text */
  padding-bottom: 10px;
  padding-top: 10px;
}

.Trend__C-body1 {
  background: #fff; /* Light background */
  padding: 10px;
  padding-bottom: .37333rem;
}

.Trend__C-body1-line {
  display: flex;
  font-size: .74667rem;
  color: #1e2637; /* Text color L1 */
  height: 1.1333rem;
  align-items: center;
  margin-bottom: 10px;
}

.Trend__C-body1-line>div:first-child {
  width: 7.70667rem;
  padding-left: .26667rem;
  text-align: left;
}

.Trend__C-body1-line-num {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-left: 2rem;
}

.Trend__C-body1-line-num>div {
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 12px;
  color: #b6bcc8; /* Text color L3 */
  text-align: center;
}

.Trend__C-body1-line.lottery .Trend__C-body1-line-num>div {
  border-radius: 50%;
  font-size: large;
  font-weight: 400;
  text-align: center;
  font-family: Roboto;
  border: .01333rem solid var(--main-color); /* Norm red color */
  color: var(--main-color) !important; /* Norm red color */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Trend__C-body2 {
  font-size: .82rem;
  background-color: #fff; /* Light background */
}

.Trend__C-body2>div {
  padding-left: 10px;
  padding-right: 10px;
}

.Trend__C-body2>div:last-child {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
}

.Trend__C-body2>div.Trend__C-body2-empty {
  height: 5.33333rem;
}

.Trend__C-body2-IssueNumber {
  color: #1e2637; /* Text color L1 */
  text-align: left;
}

.Trend__C-body2-Num {
  display: flex;
  position: relative;
  height: .62667rem;
}

.Trend__C-body2-Num>div {
  width: 1.4rem;
  height: 1.4rem;
  line-height: 1.1rem;
  border-radius: 50%;
  align-items: center;
  justify-items: center;
  display: grid;
  margin-left: 5px;
  font-size: 12px;
}

.Trend__C-body2-Num-item {
  border: .01333rem solid #b6bcc8; /* Text color L3 */
  color: #b6bcc8; /* Text color L3 */
  margin-right: .10667rem;
}

.Trend__C-body2-Num-item.action0 {
  position: relative;
  z-index: 10;
  border: none;
  color: #fff;
  background-image: linear-gradient(135deg, #fb5b5b 0, #fb5b5b 50.37%, #c86eff 50.38%, #c86eff 100%) !important;
}

.Trend__C-body2-Num-item.actionB {
  border: none;
  color: #fff;
  background-color: #feaa57; /* Norm secondary color */
}

.Trend__C-body2-Num-item.actionS {
  border: none;
  color: #fff;
  background-color: #f6f6f6; /* Background color L3 */
}

.Trend__C-body2-Num-item.action1,
.Trend__C-body2-Num-item.action3,
.Trend__C-body2-Num-item.action7,
.Trend__C-body2-Num-item.action9 {
  border: none;
  color: #fff;
  background-color: #18b660; /* Norm green color */
}

.Trend__C-body2-Num-item.action2,
.Trend__C-body2-Num-item.action4,
.Trend__C-body2-Num-item.action6,
.Trend__C-body2-Num-item.action8 {
  border: none;
  color: #fff;
  background-color: #fb5b5b; /* Norm red color */
}

.Trend__C-body2-Num-BS {
  color: #fff;
  background: #6ea8f4; /* Norm blue color */
  margin-left: .24rem;
}

.Trend__C-body2-Num-BS.isB {
  background: #feaa57; /* Norm secondary color */
}

.Trend__C-body2 .line-canvas {
  position: absolute;
  top: 50%;
  left: 0;
  height: 1.33333rem;
  width: calc(100% - .64rem);
  z-index: 9;
}

.Trend__C-foot {
  height: 1.86667rem;
  background: #f6f6f6; /* Background color L3 */
  padding: .46667rem 2.37333rem;
  margin-top: .48rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Trend__C-foot-next,
.Trend__C-foot-previous {
  width: .93333rem;
  height: .93333rem;
  border-radius: .13333rem;
  background: #f2413b; /* Main color */
  display: flex;
  align-items: center;
  justify-content: center;
}

.Trend__C-foot-next.disabled,
.Trend__C-foot-previous.disabled {
  background: #f6f6f6; /* Background color L3 */
  pointer-events: none;
}

.Trend__C-foot-next.disabled .Trend__C-icon,
.Trend__C-foot-previous.disabled .Trend__C-icon {
  color: #768096; /* Text color L2 */
}

.Trend__C-foot-next .Trend__C-icon,
.Trend__C-foot-previous .Trend__C-icon {
  color: #fff; /* White text */
}

.GreenPlusViolet {
  border: none;
    color: #fff;
    background-image: linear-gradient(to bottom right, var(--norm_green-color) 50%, var(--norm_Purple-color) 0) !important;
}

.RedPlusViolet {
  border: none;
  color: #fff;
  background-image: linear-gradient(135deg, var(--norm_red-color) 0, var(--norm_red-color) 50.37%, var(--norm_Purple-color) 50.38%, var(--norm_Purple-color) 100%) !important;
}

.GreenResultOfChart {
  border: none;
  color: #fff;
  background-color: var(--norm_green-color);
}

.RedResultOfChart {
  border: none;
  color: #fff;
  background-color: var(--norm_red-color);
}

.SmallResultOfChart {
  border: none;
  color: #fff;
  background-color: var(--norm_secondary-color);
}

.BigResultOfChart {
  border: none;
  color: #fff;
  background-color: var(--norm_bule-color);
}

.hrForchart {
    border: none;
    border-top: 2px dashed #666;
}

.hrForchart:first-child {
    border: none;
    border-top: 2px dashed #666;
}

.GameHistorypageBtn {
  background: var(--main_gradient-color2);
  text-align: center;
  padding: 5px;
  border-radius: 10px;
}

.GameHistorypageBtn button {
  background: var(--main_gradient-color2);
  color: white;
  border: none;
}

.GameHistorypageBtn button i {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  background-color: #faebeb;
  color: #000;
  border-radius: 10px;
  padding: 8px 15px;
}
.Activitycard {
  background-color: #001aff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: white;
  height: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.Activitycard:hover {
  transform: translateY(-5px);
}

.Activitycard img {
  width: 100%;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 5px;
}

.Activitycard h3 {
  margin: 0 0 8px;
  font-size: 18px;
  padding-left: 5px;
}

.Activitycard p {
  margin: 0;
  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 8px;
}

.Giftstask-list {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Giftstask-card {
  background: var(--main_gradient-color2);
  padding: 8px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Giftsheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 2px solid #fff;
}

.Giftsheader.Giftsweekly {
  border-color: #fff;
}

.Giftstitle {
  text-transform: capitalize;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.Giftsstatus {
  color: #fff;
  font-size: 14px;
}

.Giftsprogress-section {
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 12px;
}

.Giftsball {
  width: 24px;
  height: 24px;
}

.Giftsprogress-text {
  color: white;
  font-size: 14px;
}

.Giftsprogress-bar {
  background: var(--main_gradient-for-button-bg-color);
  height: 8px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.Giftsprogress {
  background: var(--main-green-bg-gradient);
  height: 100%;
  border-radius: 8px;
  transition: width 0.3s ease;
}

.Giftsaward-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Giftsaward {
  color: white;
  font-size: 14px;
}

.Giftsaward-amount {
  color: #ffc107;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
}

.Giftscomplete-btn {
  background-color: #367dff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  text-transform: capitalize;
  transition: background-color 0.3s ease;
}

.Giftscomplete-btn:hover {
  background-color: #255cbf;
}

.Giftswallet-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.hrGifts {
  margin-top: 8px;
  margin-bottom: 8px;
  color: white;
  border: 0;
  border-top: 1px solid;
  opacity: .25;
}

.GiftTopBannertask-banner {
  width: 100%;
  height: 100%;
  padding: 1rem;
  margin-bottom: .4rem;
  background: url(/public/happy/bg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  position: relative;
}

.GiftTopBannertask-banner .GiftTopBannerbanner-title {
  color: #fff;
  font-size: large;
  font-weight: 600;
}

.GiftTopBannertask-banner {
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.GiftTopBannertask-banner .GiftTopBannerbanner-content {
  display: flex;
  color: #fff;
  word-break: break-word;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.GiftTopBannertask-banner {
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.GiftCodeHead {
  padding-top: 20px;
  padding-bottom: 35px;
  padding-left: 15px;
  padding-right: 15px;
  background: var(--main_gradient-color2);
  border-radius: 10px;
}

.GiftCodeHead h4 {
  margin-top: 6px;
  margin-bottom: 0;
  color: white;
  font-size: 12px;
}

.GiftCodeInput {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 30px;
  color: #f0f1f5;
}

.GiftCodeInput input {
  font-size: 16px;
  padding: 15px 12px;
  border: none;
  border-radius: 10px;
  color: var(--main-color);
  background: #E8F0FE;
}

.GiftCodeInput input::placeholder {
  color: gray;
  opacity: 1;
}

.GiftCodeBtn {
  display: grid;
  margin-top: 20px;
}

.GiftCodeBtn button {
  justify-self: center;
  width: 100%;
  padding: 12px;
  background: var(--main_gradient-for-button-bg-color);
  color: white;
  border: none;
  font-size: 15px;
  border-radius: 25px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}



/* Carousel Container */
.VIPcarousel-container {
  width: 100%;
  max-width: 370px;
  margin: 0 auto;
}

/* Carousel Scrollable Area */
.VIPcarousel {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}

/* Hide Scrollbar */
.VIPcarousel::-webkit-scrollbar {
  display: none;
}

/* Card Centering */
.VIPvip-card-center {
  flex: 0 0 100%;
  scroll-snap-align: center;
  padding: 10px;
  transition: transform 0.3s ease-in-out;
}

/* Active Card Highlight */
/* .VIPvip-card-center.active {
  transform: scale(1.05);
} */

.VIPvip-card-silver {
  width: 350px;
  height: auto;
  background: url(/public/happy/bg1-7ff97a99.png) no-repeat center, -webkit-linear-gradient(332.71deg, #a6b7d0 21.85%, #889ebe 67.02%);
  background: url(/public/happy/bg1-7ff97a99.png) no-repeat center, linear-gradient(117.29deg, #a6b7d0 21.85%, #889ebe 67.02%);
  background-size: cover;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  color: #fff;
  position: relative;
}

.VIPvip-card-golden {
  width: 350px;
  height: auto;
  background: url(/public/happy/bg2-ee7fbf5e.png) no-repeat center, -webkit-linear-gradient(332.71deg, #f8bd83 21.85%, #e2984e 67.02%);
  background: url(/public/happy/bg2-ee7fbf5e.png) no-repeat center, linear-gradient(117.29deg, #f8bd83 21.85%, #e2984e 67.02%);
  background-size: cover;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  color: #fff;
  position: relative;
}

.VIPvip-card-pink {
  width: 350px;
  height: auto;
  background: url(/public/happy/bg3-96f1cdae.png) no-repeat center, -webkit-linear-gradient(332.71deg, #ffa493 21.85%, #ff7878 67.02%);
  background: url(/public/happy/bg3-96f1cdae.png) no-repeat center, linear-gradient(117.29deg, #ffa493 21.85%, #ff7878 67.02%);
  background-size: cover;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  color: #fff;
  position: relative;
}

.VIPvip-card-sky-blue {
  width: 350px;
  height: auto;
  background: url(/public/happy/bg4-c3caf0f8.png) no-repeat center, -webkit-linear-gradient(332.71deg, #78dbeb 21.85%, #48c7f0 67.02%);
  background: url(/public/happy/bg4-c3caf0f8.png) no-repeat center, linear-gradient(117.29deg, #78dbeb 21.85%, #48c7f0 67.02%);
  background-size: cover;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  color: #fff;
  position: relative;
}

.VIPvip-card-purple {
  width: 350px;
  height: auto;
  background: url(/public/happy/bg5-e2132369.png) no-repeat center, -webkit-linear-gradient(332.71deg, #df91fb 21.85%, #ef82d5 67.02%);
  background: url(/public/happy/bg5-e2132369.png) no-repeat center, linear-gradient(117.29deg, #df91fb 21.85%, #ef82d5 67.02%);
  background-size: cover;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  color: #fff;
  position: relative;
}

.VIPvip-card-light-green {
  width: 350px;
  height: auto;
  background: url(/public/happy/bg6-8b5d1b4f.png) no-repeat center, -webkit-linear-gradient(332.71deg, #61dca6 21.85%, #229b5f 67.02%);
  background: url(/public/happy/bg6-8b5d1b4f.png) no-repeat center, linear-gradient(117.29deg, #61dca6 21.85%, #229b5f 67.02%);
  background-size: cover;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  color: #fff;
  position: relative;
}

.VIPvip-card-dark-green {
  width: 350px;
  height: auto;
  background: url(/public/happy/bg7-535312da.png) no-repeat center, -webkit-linear-gradient(332.71deg, #57b733 21.85%, #229b5f 67.02%);
  background: url(/public/happy/bg7-535312da.png) no-repeat center, linear-gradient(117.29deg, #57b733 21.85%, #229b5f 67.02%);
  background-size: cover;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  color: #fff;
  position: relative;
}

.VIPvip-card-dark-blue {
  width: 350px;
  height: auto;
  background: url(/public/happy/bg8-8bdc102c.png) no-repeat center, -webkit-linear-gradient(332.71deg, #54baf1 21.85%, #3d77e8 67.02%);
  background: url(/public/happy/bg8-8bdc102c.png) no-repeat center, linear-gradient(117.29deg, #54baf1 21.85%, #3d77e8 67.02%);
  background-size: cover;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  color: #fff;
  position: relative;
}

.VIPvip-card-purple-pink-gradient {
  width: 350px;
  height: auto;
  background: url(/public/happy/bg9-74d6723d.png) no-repeat center, -webkit-linear-gradient(332.71deg, #d084e2 21.85%, #8d49ff 67.02%);
  background: url(/public/happy/bg9-74d6723d.png) no-repeat center, linear-gradient(117.29deg, #d084e2 21.85%, #8d49ff 67.02%);
  background-size: cover;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  color: #fff;
  position: relative;
}

.VIPvip-card-yellow-orange-gradient {
  width: 350px;
  height: auto;
  background: url(/public/happy/bg10-76abb4b7.png) no-repeat center, -webkit-linear-gradient(332.71deg, #eeaf3a 21.85%, #f98b3b 67.02%);
  background: url(/public/happy/bg10-76abb4b7.png) no-repeat center, linear-gradient(117.29deg, #eeaf3a 21.85%, #f98b3b 67.02%);
  background-size: cover;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  color: #fff;
  position: relative;
}

.VIPvip-header {
  display: flex;
  align-items: center;
}

.VIPvip-header img {
  width: 30px;
  margin-right: 8px;
}

.VIPvip-header h1 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: #ffd700;
}

.VIPstatus {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.VIPstatus img {
  width: 16px;
  margin-right: 4px;
}

.VIPstatus span {
  font-size: 14px;
  color: #90ee90;
}

.VIPvip-subtext {
  border-radius: 5px;
  margin-top: 5px;
  color: #fff;
  border: .01333rem solid #ffe2c5;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 1.4px;
}

.VIPprogress-bar-silver {
  background-color: #b9b8b7;
  height: 8px;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
}

.VIPprogress-bar-silver span {
  display: block;
  height: 100%;
  width: 2.89%;
  background: linear-gradient(rgb(255, 252, 231), rgb(255, 200, 33));
  border-radius: 4px;
}

.VIPprogress-bar-golden {
  background-color: #d67d26;
  height: 8px;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
}

.VIPprogress-bar-golden span {
  display: block;
  height: 100%;
  width: 2.89%;
  background: linear-gradient(rgb(255, 252, 231), rgb(255, 200, 33));
  border-radius: 4px;
}

.VIPprogress-bar-pink {
  background-color: rgb(240, 92, 92);
  height: 8px;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
}

.VIPprogress-bar-pink span {
  display: block;
  height: 100%;
  width: 2.89%;
  background: linear-gradient(rgb(255, 252, 231), rgb(255, 200, 33));
  border-radius: 4px;
}

.VIPprogress-bar-sky-blue {
  background-color: rgb(50, 182, 232);
  height: 8px;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
}

.VIPprogress-bar-sky-blue span {
  display: block;
  height: 100%;
  width: 2.89%;
  background: linear-gradient(rgb(255, 252, 231), rgb(255, 200, 33));
  border-radius: 4px;
}

.VIPprogress-bar-purple {
  background-color: rgb(234, 106, 202);
  height: 8px;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
}

.VIPprogress-bar-purple span {
  display: block;
  height: 100%;
  width: 2.89%;
  background: linear-gradient(rgb(255, 252, 231), rgb(255, 200, 33));
  border-radius: 4px;
}

.VIPprogress-bar-light-green {
  background-color: rgb(30, 177, 139);
  height: 8px;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
}

.VIPprogress-bar-light-green span {
  display: block;
  height: 100%;
  width: 2.89%;
  background: linear-gradient(rgb(255, 252, 231), rgb(255, 200, 33));
  border-radius: 4px;
}

.VIPprogress-bar-dark-green {
  background-color: rgb(27, 148, 88);
  height: 8px;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
}

.VIPprogress-bar-dark-green span {
  display: block;
  height: 100%;
  width: 2.89%;
  background: linear-gradient(rgb(255, 252, 231), rgb(255, 200, 33));
  border-radius: 4px;
}

.VIPprogress-bar-dark-blue {
  background-color: rgb(52, 112, 230);
  height: 8px;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
}

.VIPprogress-bar-dark-blue span {
  display: block;
  height: 100%;
  width: 2.89%;
  background: linear-gradient(rgb(255, 252, 231), rgb(255, 200, 33));
  border-radius: 4px;
}

.VIPprogress-bar-purple-plus-pink-gradient {
  background-color: rgb(128, 56, 245);
  height: 8px;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
}

.VIPprogress-bar-purple-plus-pink-gradient span {
  display: block;
  height: 100%;
  width: 2.89%;
  background: linear-gradient(rgb(255, 252, 231), rgb(255, 200, 33));
  border-radius: 4px;
}

.VIPprogress-bar-yellow-plus-orange-gradient {
  background-color: rgb(239, 123, 39);
  height: 8px;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
}

.VIPprogress-bar-yellow-plus-orange-gradient span {
  display: block;
  height: 100%;
  width: 2.89%;
  background: linear-gradient(rgb(255, 252, 231), rgb(255, 200, 33));
  border-radius: 4px;
}

.VIPinfo {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 12px;
}

.VIPdeduction {
  font-size: 12px;
  color: #fff;
  margin-top: 10px;
}

.level2 {
  background: -webkit-linear-gradient(top, #fff9d6 39.98%, #ffe650 100%);
  background: linear-gradient(180deg, #fff9d6 39.98%, #ffe650 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.VIP2Text{
  background: -webkit-linear-gradient(top, #f09238 0%, #d26d0a 100%);
  background: linear-gradient(180deg, #f09238 0%, #d26d0a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.level-progess-silver {
  background: linear-gradient(90deg, #c0bebc 0%, #8d8c8a 92.31%);
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.level-progess-golden {
  background: linear-gradient(90deg, #ef9033 0%, #c97420 92.31%);
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.level-progess-pink {
  background: linear-gradient(90deg, #ff7878 0%, #f05c5c 92.31%);
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.level-progess-sky-blue {
  background: linear-gradient(90deg, #40c4ed 0%, #30b4e9 92.31%);
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.level-progess-purple {
  background: linear-gradient(90deg, #d669fe 0%, #f951d6 92.31%);
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.level-progess-light-green {
  background: linear-gradient(90deg, #23c89d 0%, #17af87 92.31%);
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.level-progess-dark-green {
  background: linear-gradient(90deg, #67d145 0%, #27b770 92.31%);
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.level-progess-dark-blue {
  background: linear-gradient(90deg, #308fff 0%, #1277ea 92.31%);
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.level-progess-purple-plus-pink-gradient {
  background: linear-gradient(90deg, #b45aff 0%, #8945fa 92.31%);
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.level-progess-yellow-plus-orange-gradient {
  background: linear-gradient(90deg, #ff940a 0%, #eb7119 92.31%);
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.VIPBenefitspreloader-overlay {
  position: fixed; /* Covers the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Low-opacity dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears on top */
}

.VIPBenefitspreloader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* LevelUpRewards.css */
.VIPBenefitslevel-up-container-bg {
  background: var(--main_gradient-color2);
  border-radius: 10px;
  padding: 10px 15px;
  color: white;
  font-family: Arial, sans-serif;
}

/* LevelUpRewards.css */
.VIPLevelBenefitsTitle {
  display: flex;
  align-items: center;
  font-family: Comic Sans MS;
}

.VIPLevelBenefitsTitleHr {
  border-bottom: 1px solid #ddd;
  margin-top: 3px;
  margin-bottom: 10px;
}

/* LevelUpRewards.css */
.VIPBenefitslevel-up-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
}

.VIPBenefitsgift-icon {
  width: 50px;
  height: 50px;
  background-image: url('/public/happy/1-836ced4c.png');
  background-size: cover;
  background-position: center;
}

.VIPBenefitsstarcoin-icon {
  width: 50px;
  height: 50px;
  background-image: url('/public/happy/2-ad941462.png');
  background-size: cover;
  background-position: center;
}

.VIPBenefitssafe-icon {
  width: 50px;
  height: 50px;
  background-image: url('/public/happy/4-74010295.png');
  background-size: cover;
  background-position: center;
}

.VIPBenefitsrebate-icon {
  width: 50px;
  height: 50px;
  background-image: url('/public/happy/5-16611279.png');
  background-size: cover;
  background-position: center;
}

.VIPBenefitsreward-info {
  flex: 1;
  /* margin-left: ; */
}

.VIPBenefitsreward-title {
  font-size: 16px;
  font-weight: bold;
}

.VIPBenefitsreward-subtitle {
  font-size: 12px;
  color: #c0c0c0;
}

.VIPBenefitsreward-values {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.VIPBenefitsvalue-box {
  color: var(--norm_secondary-color-for-vip-level-benefits);
  border: .01333rem solid var(--norm_secondary-color-for-vip-level-benefits);
  min-width: 2rem;
  padding-left: 15px;
  text-align: center;
  padding-right: 15px;
  border-radius: 5px;
  width: 100%;  /* Make them stretch to the same width */
}

.VIPBenefitsvalue-box.VIPBenefitstop-box,
.VIPBenefitsvalue-box.VIPBenefitsbottom-box {
  width: 100%; /* Ensure both have equal width */
}

.VIPBenefitsvalue {
  font-size: 14px;
}

.VIPBenefitstop-box {
  margin-bottom: 5px;
}

.VIPBenefitsbottom-box {
  margin-top: 5px;
}

.VIPMyBenebitscard {
  background-color: #374992;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: white;
  height: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* transition: transform 0.2s; */
}

.VIPMyBenebitscard:hover {
  /* transform: translateY(-5px); */
}

.VIPMyBenebitscard img {
  width: 100%;
  height: 7rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #2AA0F3;
  /* margin-bottom: 5px; */
}

.VIPMyBenebitscard h3 {
  margin: 0 0 8px;
  font-size: 15px;
  padding-left: 5px;
}

.VIPMyBenebitscard p {
  margin: 0;
  font-size: 11px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 8px;
}

.reward-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #1D58A7, #2678D9);
  padding-top: 3px;
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  box-shadow: inset 0 -2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
}

.reward-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.reward-value {
  color: #FFFFFF;
  font-size: 16px;
}

/* VIPHeader.css */

.VIPTopHeadervip-header-container {
  background: var(--main_gradient-color2); /* Dark blue background */
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid #444;
}

.VIPTopHeadervip-title {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
}

.VIPTopHeaderback-button {
  position: absolute;
  left: 15px;
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.VIPTopHeaderback-button:hover {
  color: #ccc;
}
